/*------------------------------------------------------------------
Project Name : International School System
Desgined By  : EntireTimes
Date        :  15 September 2020;

[Table of contents]

1. Body Css
2. Header Css
3. Navigation Css
4. Custom Slider Css
5. Categories 
6. School Css 
7. About Css 
8. Class Css
9. Choice Css
10. Video Css
11. Services Css
12. Gallery Css 
13. Blog Css 
14. Testimonials Css  
15. Enrol Css  
16. Teachers Css 
17. Blog Css
18. Contact Css 
19. Newsletter Css 
20. Footer Css 
21. Copyright Css 
22. Inner page title Css
23. Class Details Css
24. Teacher Deatils Css
25. Pricing Css 
26. FAQs Css 
27. Inner Testimonials Css
28. 404 page Css
29. Login Css 
30. RegisterWrp Css 
31. Inner Blog Css
32. Categories Css
33. Tags Css
34. Media Quries Css
35. Payment Css

-------------------------------------------------------------------*/

@charset "utf-8";

/* Body */
* {
  margin: 0;
  padding: 0;
}
body {
  font-family: "Open Sans", sans-serif;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif;
}
a {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  color: #ffb607;
}
.unorderList {
  list-style: none;
}
img {
  max-width: 100%;
}
p {
  font-size: 14px;
  line-height: 26px;
  color: #52575e;
}
.style_none {
  list-style: none;
  margin-bottom: 0;
}
.clearfix {
  clear: both;
}
.style_none {
  list-style: none;
}
ul.tick {
  list-style: none;
}
ul.tick li {
  position: relative;
  padding-left: 25px;
  font-size: 15px;
  font-weight: 600;
}
ul.tick li:before {
  font-family: "Font Awesome 5 Free";
  font-weight: bold;
  content: "\f061";
  position: absolute;
  top: 0;
  left: 0;
  color: #ffb607;
}
ul.tick li {
  margin-bottom: 10px;
}
.title {
  margin-bottom: 20px;
  position: relative;
}
.title h1 {
  font-size: 48px;
  color: #56225e;
  margin: 0;
  font-weight: bold;
}

.title h1 span {
  display: block;
}
.title p {
  font-size: 20px;
  letter-spacing: 3px;
  color: #ffb607;
  text-transform: uppercase;
  margin-bottom: 0;
}
.readmore {
  margin-top: 20px;
}
.readmore a {
  text-decoration: none;
  font-size: 15px;
  background: #f0aa00;
  text-transform: uppercase;
  border-radius: 36px;
  font-weight: bold;
  display: inline-block;
  padding: 18px 38px;
  color: #fff;
}
.readmore a:hover {
  color: #fff;
  background: #1d2435;
}

/********************************************
	   == Header Css Start ==
********************************************/
.header-wrap {
}
.header-wrap .logo {
  margin-top: 6px;
}
.header-wrap .conInfo {
  padding-left: 52px;
  position: relative;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
}
.header_info {
  display: flex;
  float: right;
  margin-top: 16px;
}
.header_info div {
  border-right: 1px solid #e8e8e8;
  padding-right: 26px;
  margin-right: 30px;
}
.header_info div:last-child {
  border: none;
  margin-right: 0;
  padding-right: 0;
}
.header_info .search a {
  width: 50px;
  height: 50px;
  background: #ffb607;
  color: #fff;
  border-radius: 100%;
  display: block;
  text-align: center;
  line-height: 50px;
  font-size: 16px;
}
.loginwrp {
}
.loginwrp a {
  text-decoration: none;
  background: #ffb607;
  padding: 15px 34px;
  font-size: 16px;
  color: #fff;
  border-radius: 6px;
  display: inline-block;
  font-weight: bold;
}
.header-wrap .conInfo span {
  font-size: 12px;
  text-transform: uppercase;
  color: #a3a3a3;
  letter-spacing: 2px;
  display: block;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
}
.header-wrap .conInfo a {
  text-decoration: none;
  font-size: 20px;
  color: #000;
  font-weight: 600;
  line-height: 24px;
}
.header_info .search a:hover,
.loginwrp a:hover {
  background: #003d69;
  color: white;
}
.header-wrap .conInfo a:hover {
  color: #ffb607;
}
.header-wrap .addressWrp:before {
  content: "";
  background: url(./images/map_icon.png) no-repeat;
  width: 46px;
  height: 45px;
  position: absolute;
  top: 0px;
  left: 0;
}
.header-wrap .phonewrp:before {
  content: "";
  background: url(./images/phone_icon.png) no-repeat;
  width: 46px;
  height: 45px;
  position: absolute;
  top: 0;
  left: 0;
}
.header-wrap .phonewrp a {
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
}
.header-wrap .phonewrp {
  padding-left: 56px;
}

#profileSection {
  margin-right: 10px;
}

.imageStyle {
  width: 50px;
  border-radius: 50%;
  cursor: pointer;
  padding-top: 3px;
  object-fit: cover;
}

.dropDownsection {
  position: absolute;
  z-index: 999;
  top: 4.5rem;
  right: 7px;
  width: 210px;
  height: 240px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  background-color: white;
}
.unOrderedList {
  margin-right: 10px;
  position: "absolute";
  z-index: 999;
  display: flex;
  flex-direction: column;
}

.unOrderedList li {
  font-size: 19px;
  font-weight: 600;
  margin: 8px;
  display: flex;
  flex-direction: column;
}

.header_info {
  margin-right: 10px;
}

.unOrderedList i {
  color: grey;
}

/*********************************************
        Navigation css
*********************************************/
.navbar-brand {
  display: none;
}
.navigation-wrap {
  z-index: 1000;
  position: relative;
}
.navbar-nav li {
  padding: 0;
}
.navbar-light .navbar-nav .nav-link {
  color: #000;
  font-size: 14px;
  padding: 34px 22px;
  font-weight: bold;
  text-transform: uppercase;
}
.navbar {
  padding: 0;
}
.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .nav-item:hover .nav-link {
  color: #fff;
  background: #ffb607;
}
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link:hover {
  color: #fff;
  background: #ffb607;
}
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show > .nav-link {
  color: #fff;
  background: #ffb607;
}
.sticky {
  top: 0;
  position: fixed;
  width: 100%;
  left: 0;
  z-index: 10000;
  background: #ffb607;
  height: 65px;
  margin-top: 0;
  -webkit-box-shadow: 0px 5px 7px rgba(3, 3, 3, 0.11);
  -moz-box-shadow: 0px 5px 7px rgba(3, 3, 3, 0.11);
  box-shadow: 0px 5px 7px rgba(3, 3, 3, 0.11);
}
.custom-navbar .navbar-nav li.active > a {
  background-color: #1d2435;
  color: #fff;
}
.navbar-nav li {
  position: relative;
}
.navbar-nav > li > ul {
  list-style: none;
  position: absolute;
  left: 0;
  top: 200%;
  width: 260px;
  padding: 0px;
  z-index: 100;
  background: #fff;
  visibility: hidden;
  opacity: 0;
  border-radius: 0px 2px 2px 2px;
  -ms-border-radius: 0px 2px 2px 2px;
  -webkit-border-radius: 0px 2px 2px 2px;
  -moz-border-radius: 0px 2px 2px 2px;
  -o-border-radius: 0px 2px 2px 2px;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  box-shadow: 0 0 18px rgba(0, 0, 0, 0.38);
}
.submenu li:last-child {
  border: none;
}
.navbar-nav > li:hover > ul {
  top: 100%;
  opacity: 1;
  visibility: visible;
}
.navbar-nav > li > ul > li {
  position: relative;
  float: none;
  width: 100%;
  border-bottom: 1px solid rgb(230 230 230 / 50%);
  padding: 0;
}
.submenu > li:hover > a {
  background: #ffb607;
  color: #fff !important;
}
.navbar-nav > li > ul > li > a {
  text-decoration: none;
  position: relative;
  display: block;
  padding: 10px 20px;
  font-weight: 600;
  font-size: 14px;
  color: #000 !important;
  word-wrap: break-word;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}
.navbar-nav li > .submenu > li a + i {
  display: none;
  position: absolute;
  right: 15px;
  top: 7px;
  font-size: 24px;
  cursor: pointer;
  color: #0b3c5d;
  background: #fff;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
}
.navbar-nav > li > a + i {
  display: none;
  position: absolute;
  right: 15px;
  top: 7px;
  font-size: 24px;
  cursor: pointer;
  color: #0b3c5d;
  background: #fff;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
}
.submenu li .submenu_right {
  display: none;
}
.submenu_right {
  background: #328bc3;
  position: absolute;
  top: 0;
  left: 100%;
  width: 200px;
}
.submenu_right li {
  padding-bottom: 0;
  padding: 0px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}
.submenu_right li a {
  display: block;
  padding: 7px 10px;
  color: #fff;
  text-decoration: none;
}
.submenu_right li a:hover {
  background: #c79935;
  color: #fff !important;
}
.submenu li:hover .submenu_right {
  display: block;
}
/*********************************************
    Custom slider options
**********************************************/
.tp-banner-container {
  position: relative;
  z-index: 1;
  padding: 0;
  width: 100%;
}
.tp-banner {
  position: relative;
  width: 100%;
}
.tp-caption {
  line-height: normal !important;
}
.tp-caption a {
  color: inherit !important;
}
.tp-bullets.simplebullets.round .bullet {
  border: 3px #fff solid;
  border-radius: 50%;
  background-image: none !important;
}
.tparrows {
  width: auto !important;
  height: auto !important;
  background-image: none !important;
}
.tp-arr-allwrapper {
  margin: 0 10px;
  width: 50px;
  height: 60px;
  background: rgba(255, 255, 255, 0.5);
}
.tp-arr-allwrapper:hover .tp-arr-iwrapper {
  color: #fff;
}
.tp-leftarrow .tp-arr-allwrapper {
}
.tp-rightarrow .tp-arr-allwrapper {
}
.tp-arr-iwrapper {
  color: #000;
  text-align: center;
  font-size: 30px;
  font-family: "Font Awesome 5 Free";
  font-weight: bold;
  line-height: 60px;
}
.tp-leftarrow .tp-arr-iwrapper {
}
.tp-leftarrow .tp-arr-iwrapper:before {
  content: "\f104";
}
.tp-rightarrow .tp-arr-iwrapper:before {
  content: "\f105";
}
.tp-rightarrow .tp-arr-iwrapper {
}
.slide-h1 {
  margin: 5px 0 !important;
  padding: 0 0 10px !important;
  padding-bottom: 5px !important;
  color: #444;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 57px;
}
.slide-h2 {
  margin: 5px 0 !important;
  padding: 0 0 10px !important;
  padding-bottom: 5px !important;
  color: #555;
  font-weight: bold;
  font-size: 55px;
  line-height: 100%;
}
.big-font {
  text-transform: uppercase;
  font-weight: 800;
  font-size: 60px;
}
.large-desc {
  font-size: 16px;
}
.slide-h3 {
  color: #fff;
  font-size: 31px;
  line-height: 100%;
}
.slide-h3 span {
  text-transform: uppercase;
  font-weight: bold;
}
.slide-head {
  text-transform: uppercase;
  font-weight: 900;
  font-size: 30px;
}
.slide-desc {
  font-size: 22px;
  line-height: 150%;
}
.main-title {
  text-transform: uppercase;
  font-weight: 800;
  font-size: 70px;
}
.icon-cont {
  padding: 15px 40px;
  border-radius: 10px;
  text-align: center;
}
.icon-cont:after {
  position: absolute;
  bottom: -10px;
  left: 40%;
  z-index: 5;
  display: inline-block;
  width: 0;
  height: 0;
  border-width: 10px 10px 0 10px;
  border-style: solid;
  content: "";
}
.icon-cont i {
  margin-bottom: 10px;
  color: #fff;
  font-size: 50px;
}
.icon-cont span {
  display: block;
  padding: 0 0 5px;
  color: #fff;
  text-align: center;
  font-size: 18px;
}
a.wit-btn {
  background: #fff;
  color: #333 !important;
  text-transform: uppercase;
}
.wit-line {
  width: 5%;
  height: 1px;
  background: #fff;
}
.vert-line {
  min-height: 270px;
  width: 1px;
  background: #fff;
}
.subTxt {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 17px;
  color: #333;
}
.large-title {
  color: #222;
  font-size: 60px;
  font-weight: 800;
  text-transform: uppercase !important;
}
.large-light-title {
  color: #787878 !important;
  font-size: 70px;
  font-weight: lighter;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.large-text {
  font-size: 20px;
  color: #000;
  font-weight: lighter;
}
.larger-text {
  font-size: 24px;
  font-weight: lighter;
  text-transform: uppercase;
}
.witTxt {
  color: #fff !important;
}
.light-font {
  font-weight: lighter !important;
  font-size: 50px;
}
.black-bg {
  background: rgba(0, 0, 0, 0.63);
  color: #a8a8a8;
  text-transform: none;
}
.lft-list {
  padding: 10px;
  font-size: 18px;
  color: #fff;
}
.wit-border {
  border: 1px #fff solid;
  padding: 15px 60px;
  color: #fff;
  font-size: 16px;
  text-transform: uppercase;
}
.rounded-bord {
  margin-right: 15px;
  border: 1px #fff solid;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  font-size: 13px;
  border-radius: 50%;
}
#vertical-ticker {
  height: 120px;
  overflow: hidden;
}
#vertical-ticker li {
  padding: 15px 20px;
  display: block;
  text-align: center;
}
.to-bottom i.fa {
  font-size: 30px;
  border: 2px #a8a8a8 solid;
  padding: 20px 22px;
  border-radius: 50%;
  -webkit-animation: bounce 2s infinite linear;
  animation: bounce 2s infinite linear;
}
.slidertext1 {
  color: #fff;
  letter-spacing: 4px;
  font-size: 30px;
  font-weight: normal;
  padding: 15px 0;
  text-transform: inherit !important;
  font-family: "Montserrat", sans-serif;
  text-align: center;
  line-height: 26px !important;
}
.slidertext2 {
  color: #fff;
  font-size: 60px;
  font-weight: bold !important;
  padding: 15px 0;
  text-transform: none !important;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  font-family: "Montserrat", sans-serif;
  text-align: left;
}
.slidertext2 span {
  color: #fff;
}
.slidertext3 {
  color: #fff;
  font-size: 18px;
  font-weight: 600 !important;
  letter-spacing: 0px;
  padding: 15px 0;
  text-transform: none !important;
  line-height: 30px !important;
  font-family: "Open Sans", sans-serif;
  text-align: center;
  text-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
}
.slidertext3 span {
  color: #fff;
}
.slidertext4 i {
  margin-right: 10px;
  font-weight: normal !important;
}
.slidertext4 a {
  color: #fff !important;
  font-size: 18px;
  border-radius: 40px;
  font-weight: 700;
  padding: 22px 40px;
  text-transform: uppercase !important;
  text-align: left;
  background: #f0aa00;
  font-family: "Open Sans", sans-serif;
}
.slidertext4 a:hover {
  background: #fff;
  color: #ffb607 !important;
}
a.slidebtn {
  background: #fff;
  color: #ffb607 !important;
  padding: 15px 30px;
  font-size: 16px;
  font-weight: normal !important;
  text-transform: none !important;
  display: inline-block;
  font-family: "Montserrat", sans-serif;
}
a.slidebtn i {
  margin-left: 10px;
}
.slidertext5 a {
  background: none !important;
  border: 2px solid #fff;
  padding: 16px 40px;
}
.slidertext5 a:hover {
  background: #f0aa00 !important;
  color: #fff !important;
  border-color: #f0aa00;
}
@-webkit-keyframes bounce {
  0% {
    transform: translateY(0%);
  }
  50% {
    transform: translateY(-30%);
  }
  100% {
    transform: translateY(0);
  }
}
/* Mozilla Firefox 15 below */
@-moz-keyframes bounce {
  0% {
    transform: translateY(0%);
  }
  50% {
    transform: translateY(-30%);
  }
  100% {
    transform: translateY(0);
  }
}
/* Opera 12.0 */
@-o-keyframes bounce {
  0% {
    transform: translateY(0%);
  }
  50% {
    transform: translateY(-30%);
  }
  100% {
    transform: translateY(0);
  }
}
/* W3, Opera 12+, Firefox 16+ */
@keyframes bounce {
  0% {
    transform: translateY(0%);
  }
  50% {
    transform: translateY(-30%);
  }
  100% {
    transform: translateY(0);
  }
}

/*---------------------------------------
  Categories_wrap Css              
-----------------------------------------*/
.categories_wrap {
  z-index: 1000;
  margin-top: -70px;
  position: relative;
}
.categories-course {
  background: #3ab64c;
  padding: 15px;
  text-align: center;
  border-radius: 12px;
}
.categories-course h4 {
  font-size: 24px;
  color: #fff;
  position: relative;
  margin-bottom: 30px;
}
.categories-course h4:before {
  content: "";
  background: #fff;
  width: 40px;
  height: 2px;
  position: absolute;
  bottom: -15px;
  text-align: center;
  left: 50%;
  margin-left: -20px;
}
.categories-course p {
  font-size: 14px;
  color: #fff;
  line-height: 24px;
}
.coure-icon-inner {
  width: 130px;
  height: 130px;
  background: #fff;
  text-align: center;
  line-height: 130px;
  border-radius: 100%;
  display: inline-block;
}
.cours-icon {
  margin-bottom: 30px;
  margin-top: -80px;
}
.categories_wrap ul li:nth-child(1) .categories-course {
  background: #f0aa00;
}
.categories_wrap ul li:nth-child(2) .categories-course {
  background: #ff002a;
}
.categories_wrap ul li:nth-child(3) .categories-course {
  background: #003d69;
}
.categories_wrap ul li:nth-child(4) .categories-course {
  background: #3db2d5;
}

/*---------------------------------------
  School Css              
-----------------------------------------*/
.school-wrap {
  text-align: center;
  background: #f5f6fc;
  padding: 60px 0;
}
.school_box {
}
.school-wrap h3 {
  margin-top: 14px;
  position: relative;
  margin-bottom: 30px;
}
.school-wrap h3 a {
  font-size: 22px;
  color: #263073;
  font-weight: bold;
  text-decoration: none;
}
.school-wrap h3 a:hover {
  color: #82b941;
}
.school-wrap h3:before {
  content: "";
  background: #f2b31a;
  width: 40px;
  height: 2px;
  margin-left: -20px;
  position: absolute;
  bottom: -15px;
  left: 50%;
}
.school-wrap p {
  font-size: 14px;
  color: #000;
  min-height: 52px;
}
.school_sec {
  margin-top: -160px;
  z-index: 10;
  position: relative;
}
/*---------------------------------------
  About Css              
-----------------------------------------*/
.about-wrap {
  position: relative;
  padding: 80px 0 60px 0;
}
.aboutImg {
  margin-bottom: 15px;
}
.about-wrap .readmore {
  margin-top: 40px;
}
.about-wrap .title h1 {
  font-size: 48px;
  color: #000;
}
.about_box {
  margin-left: 30px;
}
.subText {
  font-size: 18px;
  font-weight: 600;
  color: #000;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 15px;
}

.edu_list {
  list-style: none;
}
.learing-wrp {
  display: flex;
}
.edu_icon {
  background: #000;
  width: 85px;
  height: 85px;
  line-height: 85px;
  text-align: center;
  border-radius: 100%;
  margin-right: 20px;
  display: table;
}
.edu_list li:nth-child(1) .edu_icon {
  background: #3db2d5;
}
.edu_list li:nth-child(2) .edu_icon {
  background: #3ab64c;
}
.edu_list li:nth-child(3) .edu_icon {
  background: #dd1146;
}
.learing-wrp h3 {
  font-size: 24px;
  font-weight: 600;
}
.edu_list li {
  margin-bottom: 15px;
}

/********************************
        Class Css
********************************/
.class-wrap {
  background: url(./images/class-bg.jpg) no-repeat top;
  background-size: cover;
  padding: 70px 0;
}
.class-wrap .title {
  text-align: center;
  position: relative;
  margin-bottom: 60px;
}
.class-wrap .title h1 {
  color: #fff;
}
.class-wrap .title h1 span {
  font-size: 24px;
  letter-spacing: 1px;
}
.class-wrap .title:after {
  content: "";
  width: 54px;
  height: 2px;
  background: #ddb202;
  margin-left: -26px;
  position: absolute;
  bottom: -20px;
  left: 50%;
}
.class-wrap h3 {
  position: relative;
  min-height: 66px;
}
.class-wrap h3 a {
  font-size: 22px;
  text-decoration: none;
  color: #000;
  font-weight: bold;
}
.class-wrap h3 a:hover {
  color: #f0aa00;
}
/*.class-wrap h3:before {
  content: "";
  width: 40px;
  height: 2px;
  background: #ddb202;
  position: absolute;
  bottom: -20px;
  left: 0;
}*/
.class-wrap p {
  font-size: 14px;
  line-height: 24px;
  color: #322c2c;
  margin-bottom: 0;
  min-height: 80px;
}
.class-wrap .owl-dots {
  display: none;
}
.class-wrap .owl-nav button {
  font-size: 16px;
  color: #a5a9ac;
  border: 2px solid #ccc;
  background: none;
  width: 60px;
  height: 60px;
  border-radius: 100%;
}
.class-wrap .owl-prev {
  position: absolute;
  left: -100px;
  top: 50%;
  margin-top: -24px;
}
.class-wrap .owl-next {
  position: absolute;
  right: -100px;
  top: 50%;
  margin-top: -24px;
}
.class-wrap .owl-nav button:hover {
  background: #ffa800;
  border-color: #ffa800;
  color: #fff;
}
.class-wrap .owl-nav i {
  font-size: 30px;
}
.class_box {
  background: #fff;
  max-width: 340px;
  margin: 0 auto;
  margin-left: 15px;
}
.class_Img {
  position: relative;
}
.class_Img img {
  width: 100%;
}
.path_box {
  padding: 26px 26px 16px 26px;
}

.class-wrap .path_box {
  min-height: 246px;
}
.time_box {
  background: #3ab64c;
  font-size: 14px;
  color: #fff;
  border-radius: 6px 6px 0 0;
  padding: 8px 12px;
  position: absolute;
  bottom: 0;
  right: 20px;
}

.students_box {
  /* border-top: 1px solid #e6e6e6; */
  padding: 10px;
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  background-color: green;
}
.students {
  font-size: 16px;
  color: #fff;
}
.stud_fee {
  font-size: 16px;
  color: #fff;
}
.flight-wrap .path_box {
  min-height: 177px;
}

/********************************
        Choice Css
********************************/
.choice-wrap {
  padding: 60px 0 70px 0;
  text-align: center;
  background: url(./images/choice-bg.jpg) no-repeat top;
  background-size: cover;
}
.choice-wrap .title {
  margin-bottom: 30px;
}
.choice-wrap .title h1 {
  font-size: 48px;
  font-weight: bold;
}

.choice-wrap p {
  color: #000;
  font-size: 14px;
  padding: 0 140px;
}
.callText {
  font-size: 24px;
  color: #fff;
  font-family: "Montserrat", sans-serif;
}
.choice-wrap .readmore {
  margin-top: 30px;
}
.choice-wrap .readmore a {
  background: #ee3131;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 16px;
}
.choice-wrap .readmore a:hover {
  background: #000;
  color: #fff;
}

/**********************************************
      Video Css
**********************************************/
.video-wrap {
  padding: 70px 0 90px 0;
}
.video-wrap .title {
  margin-bottom: 45px;
}
.video {
  background: url(./images/thumbnail.jpg) no-repeat top;
  background-size: cover;
  padding: 200px 0;
  border: 14px solid #fff;
  box-shadow: 0 0 18px rgb(0 0 0 / 12%);
}
.video-wrap p {
  font-size: 30px;
  font-weight: bold;
  color: #fff;
  line-height: 36px;
  text-align: center;
  margin-top: 40px;
  font-family: "Montserrat", sans-serif;
}
.playbtn {
  text-align: center;
  position: relative;
}
.playbtn a {
  box-sizing: content-box;
  display: inline-block;
  width: 32px;
  height: 44px;
  background: #fff;
  border-radius: 50%;
  padding: 18px 20px 18px 28px;
  position: relative;
}
.playbtn a:before {
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 80px;
  height: 80px;
  background: #fff;
  border-radius: 50%;
  -webkit-animation: pulse-border 1500ms ease-out infinite;
  animation: pulse-border 1500ms ease-out infinite;
}
.playbtn a span {
  display: inline-block;
  position: relative;
  z-index: 3;
  width: 0;
  height: 0;
  border-left: 32px solid #ee3131;
  border-top: 22px solid transparent;
  border-bottom: 22px solid transparent;
}
.playbtn a:after {
  content: "";
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  -webkit-transition: all 200ms;
  transition: all 200ms;
}
@-webkit-keyframes pulse-border {
  0% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0)
      scale(1.5);
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}
@keyframes pulse-border {
  0% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0)
      scale(1.5);
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 0;
  }
}

/*---------------------------------------
   Services section              
-----------------------------------------*/
.service-wrap {
  padding: 60px 0 200px 0;
  background: url(./images/service-bg.html) no-repeat top;
  text-align: center;
}
.service-wrap h1 {
  color: #fff;
}
.service-wrap .title:after {
  left: 50%;
  margin-left: -26px;
  background: #fff;
}
.service_box {
  background: #fff;
  padding: 40px;
}
.service-wrap h3 {
  margin: -20px 20px 0 20px;
  z-index: 99;
  position: relative;
}
.service-wrap h3 a {
  color: #fff;
  font-size: 30px;
  background: #56225e;
  padding: 30px;
  display: block;
  text-decoration: none;
}
.service-wrap h3 span {
  display: block;
}
.service_box {
  margin-bottom: -160px;
  position: relative;
  margin-top: 60px;
}
.page_content .service-wrap {
  background: none;
  padding: 0;
}
/*---------------------------------------
   gallery section              
-----------------------------------------*/
.gallery-wrap {
  position: relative;
  background: url(./images/gallery-bg.jpg) no-repeat top;
  background-size: cover;
  padding: 60px 0;
}
.gallery_box {
  display: table;
  height: 100%;
}
.gallery_left {
  display: table-cell;
  vertical-align: middle;
}
.galleryImg {
  margin: 15px 0;
  position: relative;
}
.gallery-wrap .col-lg-4:nth-child(2) .galleryImg + .galleryImg {
  margin-top: 30px;
}
.gallery_style1 .col-lg-4:nth-child(4) .galleryImg {
  margin-top: 30px;
}
.gallery_style1 .col-lg-4:nth-child(5) .galleryImg {
  margin-top: 30px;
}
.gallery_style1 .col-lg-4:nth-child(6) .galleryImg {
  margin-top: 30px;
}
.gallery_style1 .col-lg-3:nth-child(5) .galleryImg {
  margin-top: 30px;
}
.gallery_style1 .col-lg-3:nth-child(6) .galleryImg {
  margin-top: 30px;
}
.gallery_style1 .col-lg-3:nth-child(7) .galleryImg {
  margin-top: 30px;
}
.gallery_style1 .col-lg-3:nth-child(8) .galleryImg {
  margin-top: 30px;
}
.gallery_style1 .col-lg-6:nth-child(3) .galleryImg {
  margin-top: 30px;
}
.gallery_style1 .col-lg-6:nth-child(4) .galleryImg {
  margin-top: 30px;
}

.serImg {
  margin-bottom: 30px;
}
.galley_inner .row > div {
  margin: 15px 0;
}
.galley_inner {
  text-align: center;
}
.testi_list {
  list-style: none;
}
.testi_list li {
  margin: 36px 0;
}
.testi_list .testimonials_sec {
  box-shadow: 0 0 18px rgba(0, 0, 0, 0.1);
}
.portfolio-overley {
  z-index: 1000;
  position: absolute;
  width: 100%;
  height: 100%;
  display: block;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  text-align: center;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.galleryImg img {
  width: 100%;
}
.gallery-wrap .galleryImg:hover .portfolio-overley,
.galley_inner .galleryImg:hover .portfolio-overley {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
.gallery-wrap .content,
.galley_inner .content {
  z-index: 1000;
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
}
.gallery-wrap .content .image-link,
.galley_inner .content .image-link {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 50px;
  height: 50px;
  color: #ffffff;
  line-height: 50px;
  background: #f0aa00;
  display: block;
  font-size: 16px;
  z-index: 1;
  text-align: center;
  margin-left: -25px;
  margin-top: -25px;
}

/*---------------------------------------
   Blog section              
-----------------------------------------*/
.blog-section {
  position: relative;
  padding: 60px 0;
}
.single-blog {
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.single-blog .blog-content {
  padding-top: 30px;
  padding-bottom: 30px;
}
.single-blog .blog-content ul {
  margin-bottom: 15px;
}
.single-blog .blog-content ul li {
  display: inline-block;
  padding-right: 30px;
  font-weight: 500;
}
.single-blog .blog-content ul li .admin {
  color: #343434;
}
.single-blog .blog-content ul li i {
  color: #ffb607;
  margin-right: 5px;
  display: inline-block;
  position: relative;
  top: 1px;
  font-size: 15px;
}
.single-blog .blog-content ul li:last-child {
  padding-right: 0;
}
.single-blog .blog-content a h3 {
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  font-size: 22px;
  text-transform: capitalize;
  font-weight: 600;
  line-height: 1.4;
  color: #212121;
}
.single-blog .blog-content a:hover h3 {
  color: #ffb607;
  text-decoration: none;
}
.single-blog .blog-content a:hover {
  color: #ffb607;
  text-decoration: none;
}
.blog-content {
  font-size: 15px;
  margin-bottom: 25px;
  line-height: 1.7;
}
.single-blog:hover {
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
}
.blog-section .col-lg-4:nth-child(4) .single-blog .blog-content {
  margin-bottom: 0px;
  padding-bottom: 0px;
}
.blog-section .col-lg-4:nth-child(5) .single-blog .blog-content {
  margin-bottom: 0px;
  padding-bottom: 0px;
}
.blog-section .col-lg-4:nth-child(6) .single-blog .blog-content {
  margin-bottom: 0px;
  padding-bottom: 0px;
}
.blog-section .blog_details_desc .article_content {
  margin-top: 0;
  margin-bottom: 30px;
}
.blog_details_desc .article_content .entry_meta {
  margin-bottom: -10px;
}
.blog_details_desc .article_content .entry_meta ul {
  padding-left: 0;
  margin-bottom: 15px;
  list-style-type: none;
}
.blog_details_desc .article_content .entry_meta ul {
  padding-left: 0;
  margin-bottom: 15px;
  list-style-type: none;
}
.blog_details_desc .article_content .entry_meta ul li {
  position: relative;
  display: inline-block;
  color: #ffb607;
  margin-right: 21px;
  font-size: 14px;
}
.blog_details_desc .article_content .entry_meta ul li::before {
  content: "";
  position: absolute;
  top: 11px;
  right: -15px;
  width: 6px;
  height: 1px;
  background: #ffb607;
}
.blog_details_desc .article_content .entry_meta ul li span {
  display: inline-block;
  color: #ffb607;
  font-weight: 500;
}
.blog_details_desc .article_content .entry_meta ul li a {
  display: inline-block;
  color: #666666;
  font-size: 13px;
}
.blog_details_desc .article_content h3 {
  margin-bottom: 30px;
  margin-top: 0;
  font-size: 28px;
  text-transform: capitalize;
  font-weight: 600;
  line-height: 1.4;
  color: #212121;
}
.blog_details_desc .article_content .article-image {
  margin-bottom: 30px;
  position: relative;
}
.article-image img {
  width: 100%;
}
.blog_details_area blockquote {
  overflow: hidden;
  background-color: #f7f7f7;
  padding: 50px !important;
  position: relative;
  z-index: 1;
  margin-bottom: 20px;
  margin-top: 20px;
  border-radius: 4px;
}
.blog_details_area blockquote::before {
  color: #ffb607;
  content: "\ed67";
  position: absolute;
  left: 50px;
  top: -50px;
  z-index: -1;
  font-family: "boxicons";
  font-size: 140px;
  font-weight: 900;
  opacity: 0.1;
}
.blog_details_area blockquote p {
  color: #212121;
  line-height: 1.6;
  margin-bottom: 0;
  font-style: italic;
  font-weight: 600;
  font-size: 17px !important;
  text-align: left;
}
.blog_details_area blockquote::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 3px;
  background-color: #ffb607;
  margin-top: 20px;
  margin-bottom: 20px;
}
.article_content h3 {
  margin-bottom: 30px;
  margin-top: 0;
  font-size: 28px;
}
.comments-area {
  padding: 0;
  margin-top: 30px;
}
.comments-area .comments-title {
  position: relative;
  margin-bottom: 30px;
  line-height: initial;
  font-size: 22px;
}
.comments-area .comment-list {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.comments-area .comment-body {
  border-bottom: 1px solid #eeeeee;
  padding-left: 65px;
  font-size: 14px;
  margin-bottom: 20px;
  padding-bottom: 20px;
}
.comments-area .comment-meta {
  margin-bottom: 0.8em;
}
.comments-area .comment-author {
  font-size: 16px;
  margin-bottom: 0.4em;
  position: relative;
  z-index: 2;
}
.comments-area .comment-author .avatar {
  height: 50px;
  left: -65px;
  position: absolute;
  width: 50px;
  border-radius: 50%;
}
.comments-area .comment-author .fn {
  font-weight: 500;
  color: #212121;
  font-size: 16px;
}
.comments-area .comment-metadata {
  color: #666666;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 500;
}
.comments-area .comment-metadata a {
  color: #666666;
  font-size: 11px;
}
.comments-area .comment-content p {
  font-size: 14px;
}
.comments-area .comment-body .reply {
  margin-top: 15px;
}
.comments-area .comment-body .reply a {
  border: 1px solid #ded9d9;
  color: #212121;
  display: inline-block;
  padding: 5px 20px;
  border-radius: 30px;
  text-transform: uppercase;
  position: relative;
  z-index: 1;
  font-size: 13px;
  font-weight: 500;
}
.comments-area .comment-body .reply a:hover {
  color: #ffffff;
  background-color: #ffb607;
  border-color: #ffb607;
}
.comments-area .children {
  margin-left: 70px;
}
.comments-area ol {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.comment_respond {
  margin-top: 30px;
  -webkit-box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);
  padding: 30px;
}
.comment_respond .comment-reply-title {
  margin-bottom: 15px;
  font-size: 22px;
}
.comment_respond .comment-form {
  overflow: hidden;
}
.comment_respond .comment-form-author {
  float: left;
  width: 50%;
  padding-right: 10px;
  margin-bottom: 20px;
}
.comment_respond .comment-form-email {
  float: left;
  width: 50%;
  padding-left: 12px;
  margin-bottom: 20px;
}
.comment_respond label {
  display: block;
  font-weight: 500;
  color: #212121;
  margin-bottom: 5px;
}
.comment_respond input[type="text"] {
  display: block;
  width: 100%;
  background-color: #f7f7f7;
  border: 1px solid #eeeeee;
  padding: 0.625em 0.7375em;
  outline: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.comment_respond input[type="email"] {
  display: block;
  width: 100%;
  background-color: #f7f7f7;
  border: 1px solid #eeeeee;
  padding: 0.625em 0.7375em;
  outline: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.comment_respond .comment-form-ph {
  float: left;
  width: 100%;
  margin-bottom: 20px;
}
.comment_respond input[type="phone"] {
  display: block;
  width: 100%;
  background-color: #f7f7f7;
  border: 1px solid #eeeeee;
  padding: 0.625em 0.7375em;
  outline: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.comment_respond .comment-form-comment {
  margin-bottom: 30px;
  float: left;
  width: 100%;
}
.comment_respond textarea {
  display: block;
  width: 100%;
  background-color: #f7f7f7;
  border: 1px solid #eeeeee;
  padding: 0.625em 0.7375em;
  outline: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.comment_respond .form-submit {
  float: left;
  width: 100%;
}
.comment_respond .form-submit input {
  background: #d80650;
  border: none;
  color: #ffffff;
  padding: 14px 30px 12px;
  display: inline-block;
  cursor: pointer;
  outline: 0;
  border-radius: 0;
  text-transform: uppercase;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  font-weight: 500;
  font-size: 14px;
}

/*---------------------------------------
   Testimonials section              
-----------------------------------------*/
.testimonials-wrap {
  padding: 60px 0;
  text-align: center;
}
.testimonials_sec p {
  font-size: 14px;
  line-height: 22px;
  color: #dadada;
  font-style: italic;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Number of lines to display */
  -webkit-box-orient: vertical;
  white-space: normal; /* Prevent text from wrapping */
  overflow: hidden; /* Hide overflowing content */
  text-overflow: ellipsis; /* Display an ellipsis (...) when text overflows */
}
.testimonials-wrap .title {
  margin-bottom: 50px;
}
.testimonials-wrap .title:after {
  left: 50%;
  margin-left: -28px;
}
.testimonials-wrap .title h1 {
  color: #000;
}
.testimonials-wrap .title p {
  color: #b2b2b2;
}
.testimonials_sec h3 {
  font-size: 18px;
  color: #fff;
  margin-top: 12px;
  display: inline-block;
  font-weight: bold;
  text-transform: uppercase;
}
.testimonials_sec h3 span {
  display: block;
  font-size: 14px;
  color: #dadada;
  font-weight: normal;
  margin-top: 4px;
  letter-spacing: 1px;
  text-transform: none;
}
.clientImg {
  text-align: center;
  margin-bottom: 25px;
  margin-top: -76px;
  margin-right: 20px;
}
.clientImg img {
  border-radius: 100px;
}
.client_name {
  font-size: 24px;
  color: #2a9ee2;
  line-height: 24px;
  font-family: "Montserrat", sans-serif;
}
.client_name span {
  font-size: 14px;
  color: #282e32;
  display: block;
  font-family: "Open Sans", sans-serif;
}
.right_info {
  float: right;
}
.testimonials-wrap ul li {
  padding: 50px 0;
}
.testimonials-wrap .owl-dots {
  text-align: center;
  margin-top: 25px;
}
.testimonials-wrap .owl-dots button {
  width: 20px;
  height: 20px;
  border: 2px solid #e20c0c;
  border-radius: 100%;
  background: #fff;
  margin: 5px;
}
.testimonials-wrap button.active {
  background: #e20c0c;
}
.clientImg img {
  border: 1px solid #000;
}
.client_comment {
  font-size: 18px;
  color: #2a9ee2;
  font-family: "Montserrat", sans-serif;
}
.client_rating li {
  display: inline-block;
  padding: 0 2px;
  color: #ffa800;
  padding: 0 !important;
}
.quote_icon {
  position: absolute;
  bottom: 18px;
  right: 50px;
  font-size: 34px;
  width: 70px;
  height: 70px;
  line-height: 70px;
  text-align: center;
  border-radius: 100%;
  background: #fff;
  border: 1px solid #dadada;
  color: #e20c0c;
}
button:focus {
  outline: none;
}
.testimonials-wrap .owl-nav {
  display: none;
}
.tagline-wrap {
  text-align: center;
  background: #e66814;
  padding: 40px 0;
}
.tagline-wrap h3 {
  font-size: 30px;
  color: #fff;
}
.testimonials_sec {
  text-align: center;
  padding: 30px;
  background: #424761;
  border: 1px solid #424761;
}
.testimonials-wrap {
  text-align: center;
}
.testimonials-wrap ul {
  margin-bottom: 0;
}
.starWrp {
  text-align: center;
  margin-bottom: 15px !important;
}
.starWrp li {
  display: inline-block;
  font-size: 18px;
  color: #ffeb00;
  padding: 0 !important;
}

/*********************************************
        Enroll css
*********************************************/
.enroll-wrap {
  background: url(./images/enroll-bg.jpg) !important;
  padding: 100px 0;
}
.enroll-wrap .phonewrp img {
  margin-right: 15px;
}
.enroll-wrap .phonewrp a {
  font-size: 32px !important;
  color: #fff;
}
.enroll-wrap .title {
  margin-bottom: 15px;
}
.enroll-wrap .title h1 {
  color: #fff;
}
.enroll-wrap p {
  font-size: 16px;
  color: #fff;
}

/*********************************************
        Teachers css
*********************************************/
.teacher-wrap {
  padding: 60px 0;
}
.single-teachers .teachers-content ul li {
  display: inline-block;
  padding: 0 10px;
}

.single-teachers .teachers-content {
  box-shadow: 0 0 15px rgb(0 0 0 / 4%);
  position: relative;
  padding: 30px;
  background-color: #fff;
}

.teachers-content {
  text-align: center;
}
.teachers-content h3 {
  font-size: 24px;
  font-weight: 600;
}
.designation {
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 2px;
}
.center_title {
  text-align: center;
}
.teacher-wrap .title {
  margin-bottom: 50px;
}
.teacherImg {
  position: relative;
  overflow: hidden;
}
.single-teachers .social-icons {
  position: absolute;
  bottom: -60px;
  left: 0px;
  width: 100%;
  right: auto;
  text-align: center;
  padding: 14px;
  margin: 0;
  opacity: 0;
  z-index: 9;
  background-color: rgb(226 12 12 / 80%);
  -webkit-transition: 0.5s all ease;
  -ms-transition: 0.5s all ease;
  -moz-transition: 0.5s all ease;
  -o-transition: 0.5s all ease;
  transition: 0.5s all ease;
}
.single-teachers:hover .social-icons {
  opacity: 1;
  bottom: 0px;
}
.single-teachers .social-icons a {
  font-size: 16px;
  color: #fff;
  position: relative;
}
.single-teachers .social-icons li {
  margin: 4px 0;
  padding: 0 10px;
  display: inline-block;
}
.single-teachers {
}

/*********************************************
        Blog css
*********************************************/
.blog-wrap {
  background-image: none;
  background: #f8f8f8;
  padding: 60px 0;
}
.blog-wrap .title {
  text-align: center;
  margin-bottom: 40px;
}
.blog-wrap .title h1 {
  color: #000;
}
.blog-wrap .blog_box {
  background: #fff;
}
.blog-wrap .blog_box .path_box p {
  display: -webkit-box;
  -webkit-line-clamp: 4; /* Number of lines to display */
  -webkit-box-orient: vertical;
  white-space: normal; /* Prevent text from wrapping */
  overflow: hidden; /* Hide overflowing content */
  text-overflow: ellipsis; /* Display an ellipsis (...) when text overflows */
}
.blog-wrap .blogImg {
  position: relative;
}
.blog-wrap h3 a {
  text-decoration: none;
  color: #000;
  font-size: 24px;
  font-weight: 600;
}
.blog-wrap h3 a:hover {
  color: #f00;
}

/*********************************************
        Contact css
*********************************************/
.contact-wrap {
  position: relative;
  overflow: hidden;
  padding: 60px 0;
}

.contact-wrap p {
  font-size: 14px;
  line-height: 24px;
  color: #000;
  margin-bottom: 20px;
}
.address-item {
  padding: 40px;
  background-color: #fafafa;
  border-radius: 10px;
  text-align: center;
}
.address-item .address-icon {
  background: #ff002a;
  height: 70px;
  width: 70px;
  color: #fff;
  line-height: 70px;
  border-radius: 100%;
  position: relative;
  margin: 0 auto 15px;
  font-size: 32px;
}
.address-item .address-icon:before {
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 70px;
  height: 70px;
  background: #ff002a;
  border-radius: 50%;
  animation: pulse-border 1.5s ease-out infinite;
  opacity: 0;
  visibility: hidden;
}
.address-item .address-text .contact-title {
  font-size: 22px;
  margin: 0 0 10px;
  font-weight: 700;
  color: #000;
}
.cont_info {
  margin-bottom: 60px;
}
.address-item .address-text ul li {
  font-size: 14px;
  line-height: 26px;
  font-weight: 400;
  color: #505050;
}
.address-item .address-text ul li a {
  color: #505050;
}

.form-group .btn {
  background: #ffb607;
  display: inline-block;
  border-radius: 30px;
  font-weight: 600;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  color: #fff;
  text-transform: uppercase;
  padding: 16px 40px;
}
.form-group .btn:hover {
  background: #e20c0c;
  color: #fff;
}
.contact-wrap .form-control {
  height: 50px;
  width: 100%;
  padding-left: 15px;
  border-radius: 0;
  box-shadow: none;
  text-shadow: none;
  border: none;
  border: 1px solid #f2f0fa;
  background: none;
  color: #000;
  font-size: 14px;
}
.contact-wrap textarea.form-control {
  height: 160px;
}
.contact_form {
  padding: 0px 50px 30px 0px;
}
.contact_info {
  padding: 55px 0px 30px 50px;
  background: url(./images/contact-bg.html) no-repeat top;
  background-size: cover;
}
.contact-wrap ::placeholder {
  color: #71567e;
  opacity: 1;
}
.contact-wrap :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #71567e;
}
.contact-wrap ::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #71567e;
}
.contact_info {
  position: relative;
}
.contact_info .title:after {
  background: #fff;
}
.contact_box {
  display: flex;
  margin-bottom: 25px;
}
.contact_box p {
  font-size: 16px;
  color: #fff;
  font-weight: bold;
  margin-left: 20px;
  text-transform: uppercase;
}
.contact_box p span {
  display: block;
  font-weight: normal;
  font-size: 14px;
  text-transform: none;
}
.contact_box p span a {
  text-decoration: none;
  color: #fff;
}
.contact_info .title {
  margin-bottom: 60px;
}
.contact_form {
  background: #ffb607;
  padding: 40px;
}
.contact-wrap .input-group {
  margin-top: 12px;
}
.contact-wrap .input-group .form-control {
  border-radius: 0;
  background: #fff;
  height: 46px;
  border: none;
  font-size: 14px;
}
.contact-wrap textarea.form-control {
  height: 124px !important;
}
.contact-wrap .sub {
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.15);
  color: #000;
  font-size: 16px;
  text-transform: uppercase;
  background: #fff;
  font-weight: bold;
  padding: 16px 38px;
  border-radius: 30px;
  border: none;
  display: inline-block;
}
.contact-wrap .sub:hover {
  background: #000;
  color: #fff;
}
.contact_form .title h1 {
  font-size: 32px;
  font-weight: 600;
  color: #fff;
}
.contact_form .title:after {
  background: #fff;
}
.conInfo {
  padding-left: 66px;
  position: relative;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
}

.conInfo span {
  font-size: 12px;
  text-transform: uppercase;
  color: #a3a3a3;
  letter-spacing: 2px;
  display: block;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
}
.phonewrp a {
  font-size: 24px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  color: #000;
  text-decoration: none;
}
.emailWrp a {
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  color: #000;
  text-decoration: none;
}
.emailWrp:before {
  background: url(./images/email_icon.png) no-repeat;
  width: 49px;
  height: 37px;
}
.phonewrp a:hover {
  color: #ffb607;
}
.mapwrp {
  margin-top: 20px;
}
.mapwrp iframe {
  width: 100%;
  height: 300px;
}
/*********************************************
        Newsletter css
*********************************************/
.newsletter-wrap {
  background: #e20c0c;
  padding: 35px 45px;
  z-index: 1000;
  position: relative;
}
.newsletter-wrap .title {
  margin-bottom: 0;
  margin-top: 10px;
}
.newsletter-wrap .title:after {
  display: none;
}
.newsletter-wrap .title h1 {
  font-size: 48px;
  color: #fff;
  margin-bottom: 0;
}
.newsletter-wrap .title h1 span {
  font-weight: 600;
  color: #fff;
  font-size: 24px;
}
.newsletter-wrap form {
  position: relative;
}
.newsletter-wrap p {
  font-size: 18px;
  letter-spacing: 1px;
  color: #fff;
}
.news-info {
  margin-top: 10px;
}
.newsletter-wrap .form-control {
  border: none;
  border-radius: inherit;
  box-shadow: inherit;
  padding: 34px 22px 34px 65px;
  font-size: 16px;
  color: #767676;
}
.newsletter-wrap .sigup {
  background: #000 none repeat scroll 0 0;
  border: medium none;
  z-index: 1000;
  color: #ffffff;
  font-size: 16px;
  cursor: pointer;
  font-weight: bold;
  padding: 0 30px;
  position: absolute;
  right: 0px;
  text-transform: uppercase;
  bottom: 0px;
  border-radius: 0;
  top: 0px;
  transition: all 0.35s ease-in-out;
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -ms-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;
}
.newsletter-wrap form input[type="submit"]:hover {
  background: #000;
}
.newsletter-wrap .form_icon {
  font-size: 24px;
  position: absolute;
  top: 50%;
  z-index: 1000;
  color: #e1e2e5;
  margin-top: -11px;
  line-height: 0;
  left: 25px;
}
/*********************************************
        Footer css
*********************************************/
.footer-wrap {
  background: #232936;
  background-size: cover;
  padding: 50px 0 20px 0;
}
.footer-wrap p {
  font-size: 14px;
  color: #bcc5d0;
  margin-top: 15px;
}

.footer-wrap h3 {
  font-size: 18px;
  margin-bottom: 20px;
  display: inline-block;
  font-weight: bold;
  font-family: "Open Sans", sans-serif;
  color: #fff;
  margin-bottom: 25px;
}
.footer-links {
  list-style: none;
  margin-bottom: 0;
}
.footer-links li {
  margin-bottom: 10px;
}
.footer-links li a {
  text-decoration: none;
  color: #bcc5d0;
  font-size: 14px;
}
.footer-links li:before {
  content: "\f0da";
  font-weight: 600;
  font-family: "Font Awesome 5 Free";
  padding-right: 10px;
  color: #bcc5d0;
  font-size: 14px;
}
.footer-links li a:hover {
  color: #e47b9b;
}
.insta_pics {
}
.insta_pics li {
  display: inline-block;
  padding-right: 5px;
  margin-bottom: 9px;
}
.hourswrp {
}
.hourswrp li {
  font-size: 14px;
  color: #bcc5d0;
  border-bottom: 1px solid #484848;
  padding: 5px 0;
}
.hourswrp li span {
  float: right;
}
.hourswrp li:first-child {
  padding-top: 0;
}
.hourswrp li:last-child {
  border: none;
}
.footer_info {
  margin-left: 14px;
}

.footer-adress {
  list-style: none;
}
.footer-adress li {
  margin: 0;
  padding-bottom: 15px;
  display: flex;
}
.footer-adress li > i {
  vertical-align: top;
  font-size: 16px;
  color: #bcc5d0;
  width: 30px;
  padding-top: 3px;
}
.footer-adress li span a,
.footer-adress li span {
  font-size: 14px;
  color: #bcc5d0;
  text-decoration: none;
}
.footer-adress li span a:hover {
  color: #77dda7;
}
.footer_phone a {
  font-size: 14px !important;
  /* font-weight: bold; */
}
.footer_address i,
.footer_phone i {
  font-size: 14px !important;
}
.footer_icon {
  text-align: left;
}
.footerLinks li {
  position: relative;
  padding-left: 16px;
}
.footerLinks li a {
  font-size: 14px;
  color: #fff;
  line-height: 30px;
  text-decoration: none;
}
.footerLinks li:before {
  content: "\f105";
  font-family: "FontAwesome";
  position: absolute;
  top: 4px;
  left: 0;
  font-size: 14px;
  color: #fff;
}
.footerLinks li a:hover {
  color: #328bc3;
}
.footer-bottom {
  padding: 20px 0;
}
.footer_logo {
  background-color: #fff;
}
.copyright-text {
  font-size: 14px;
  color: #000;
}

.social-icons ul li {
  display: inline-block;
  margin-right: 3px;
}
.social-icons ul li a {
  border: 1px solid #fff;
  display: block;
  font-size: 18px;
  text-align: center;
  color: #fff;
  width: 38px;
  height: 38px;
  line-height: 38px;
  border-radius: 100%;
}

.social-icons ul li:nth-child(1) a {
  background: #3c599b;
  border-color: #3c599b;
}
.social-icons ul li:nth-child(2) a {
  background: #1ea1f3;
  border-color: #1ea1f3;
}
.social-icons ul li:nth-child(3) a {
  background: #cd2e80;
  border-color: #cd2e80;
}
.social-icons ul li:nth-child(4) a {
  background: #fb000f;
  border-color: #fb000f;
}

/*********************************************
        Copyright css
*********************************************/
.copyright {
  font-size: 14px;
  color: #fff;
  padding-top: 15px;
  text-transform: capitalize;
}
.credits {
  font-size: 14px;
  color: #fff;
  padding-top: 5px;
}
.credits a {
  color: #fff;
  text-decoration: none;
}
.credits a:hover {
  color: #f2b31a;
}
.close-toggler {
  display: none;
}
.navbar-toggler {
  display: none;
}

/*********************************************
        Inner page title css
*********************************************/
.innerHeading-wrap {
  padding: 30px 0;
  background: url(./images/inner_title_bg.jpg) no-repeat top;
  background-size: cover;
  text-align: center;
}
.innerHeading-wrap h1 {
  margin-bottom: 5px;
  font-size: 36px;
  font-weight: 600;
  color: #fff;
  text-transform: uppercase;
}
.innerHeading-wrap ul {
  margin: 0px;
}
.innerHeading-wrap ul li {
  list-style: none;
  display: inline-block;
  color: #fff;
  margin-top: 5px;
  padding: 0px 4px;
  font-size: 15px;
}
.innerHeading-wrap ul li a {
  color: #fff;
  text-decoration: none;
}

.innerContent-wrap {
  padding: 80px 0;
}
.innerContent-wrap .class-wrap {
  padding: 0;
  background: none;
}
.innerContent-wrap .class-wrap .class_box {
  background: #f7f7f7;
  margin: 15px auto;
}

/*********************************************
        Class Details css
*********************************************/
.class_left .class_Img {
  margin-bottom: 30px;
}
.class_left .class_Img img {
  border-radius: 30px;
}
.class_left p {
  border: 2px solid #ccc; /* Add border */
  padding: 10px; /* Add padding for better appearance */
  border-style: dashed;
}
blockquote {
  padding: 40px 50px;
  background: #fff8e8;
  padding-left: 109px;
  margin: 28px 0;
  color: #000;
  font-family: "Montserrat", sans-serif;
  position: relative;
}
blockquote::before {
  content: "\f10d";
  left: 50px;
  top: 38px;
  position: absolute;
  color: #ffb607;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 45px;
}

.sidebar-item .class-details {
  margin: 0px;
  border: 1px solid #f0f0f0;
  margin-top: 70px;
}
.sidebar-item .class-details li {
  overflow: hidden;
  list-style: none;
  border-bottom: 1px solid #f0f0f0;
  font-weight: bold;
  padding: 9px 15px;
  background: #f7f7f7;
}
.sidebar-item .class-details li:nth-child(even) {
  background: #fff;
}
.sidebar-item .class-details .name {
  float: left;
  width: 50%;
  font-size: 15px;
  color: #ffc000;
}
.sidebar-item .class-details .name i {
  margin-right: 10px;
  display: inline-block;
  width: 22px;
}
.sidebar-item .class-details .info {
  float: left;
  width: 50%;
  font-style: italic;
  font-weight: 700;
  font-size: 16px;
  color: #b3b3b3;
}
.sidebar-item .info.rating i {
  color: #fcb614;
}
.sidebar-item .class-details li:nth-child(2) .name {
  color: #e84b3a;
}
.sidebar-item .class-details li:nth-child(3) .name {
  color: #e600ff;
}
.sidebar-item .class-details li:nth-child(4) .name {
  color: #fb720d;
}
.sidebar-item .class-details li:nth-child(5) .name {
  color: #0fbaf4;
}
.sidebar-item .class-details li:nth-child(6) .name {
  color: #008c99;
}
.sidebar-item .class-details li:nth-child(7) .name {
  color: #ee257c;
}
.sidebar-item .class-details li:nth-child(8) .name {
  color: #88c87b;
}

.single-widgets.widget_category ul li a {
  text-decoration: none;
  color: #000;
  font-size: 14px;
}
.single-widgets.widget_category ul li a:hover {
  color: #ffb900;
}
.single-widgets.widget_category ul li:first-child {
  border-top: none;
}
.single-widgets.widget_category ul li a span {
  float: right;
}
.single-widgets.widget_category ul li {
  display: block;
  border-bottom: 1px solid #eaeaea;
  padding: 10px 0;
}
.property_sec li {
  padding: 16px 0 !important;
}
.property_sec li:first-child {
  border-top: none !important;
  padding-top: 0 !important;
}
.single-widgets ul {
  margin-bottom: 0;
}
.single-widgets.widget_category ul li:first-child {
  border-top: none;
  padding-top: 0;
}
.single-widgets.widget_category ul li:last-child {
  border: none;
  padding-bottom: 0;
}
.rec_proprty {
  display: flex;
}
.propertyImg {
  overflow: hidden;
}
.propertyImg img {
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  width: 100px;
}
.property_info {
  width: 150px;
  margin-left: 16px;
}
.rec_proprty p {
  margin-bottom: 0;
  line-height: 22px;
}
.single-widgets h4 {
  line-height: 16px;
  font-size: 21px;
  margin-bottom: 20px;
}
.rec_proprty h4 {
  margin-bottom: 10px;
}

/**********************************************
            Teacher Deatils Css
**********************************************/
.innerteacher-wrap .single-teachers {
  margin: 15px 0;
}
.teacher_delImg img {
  width: 100%;
}
.teacher_del h3 {
  margin-bottom: 0;
  font-size: 36px;
  font-weight: bold;
}
.teacher_del .designation {
  margin-bottom: 20px;
}
.teacher_left {
  margin-right: 20px;
}
.teacher-address li {
  list-style: none;
  margin-bottom: 6px;
  font-size: 16px;
  color: #707070;
}
.teacher-address li span {
  display: inline-block;
  height: 40px;
  width: 40px;
  border: 1px solid #f0f0f0;
  border-radius: 100%;
  text-align: center;
  font-size: 16px;
  line-height: 40px;
  color: #ffc000;
  margin-right: 10px;
}
.social-default {
  text-align: center;
  margin-top: 20px;
}
.social-default li {
  display: inline-block;
}
.social-default li a {
  display: inline-block;
  height: 50px;
  width: 50px;
  text-align: center;
  font-size: 18px;
  line-height: 50px;
  background-color: #365899;
  color: #fff;
  border-radius: 100%;
}

.social-default li:nth-child(2) a {
  background-color: #ea4c89;
}
.social-default li:nth-child(3) a {
  background-color: #dc4a38;
}
.social-default li:nth-child(4) a {
  background-color: #1da1f2;
}
.social-default li:nth-child(5) a {
  background-color: #bd081c;
}

.progress-line-wrap {
  position: relative;
  overflow: hidden;
  display: block;
  width: 100%;
  height: 25px;
  border-radius: 10px;
  background: #f1f1f9;
}
.progress-line-wrap .progress-line.color-1 {
  background: #5dba3b;
}
.progress-wrap:nth-child(4n + 2) .progress-line-wrap .progress-line {
  background: #17caff;
}
.progress-wrap:nth-child(4n + 4) .progress-line-wrap .progress-line {
  background: #ffc000;
}
.progress-wrap:nth-child(4n + 3) .progress-line-wrap .progress-line {
  background: #ff5157;
}
.progress-wrap .progress-line-wrap .progress-line {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0%;
  border-radius: 10px;
  background: #5dba3b;
  transition: all 1.5s;
  transition-timing-function: cubic-bezier(0.105, 0.01, 0.54, 1.255);
}
.progress-wrap {
  margin-bottom: 20px;
}
.progress-wrap .progress-head {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  color: #090605;
  margin-bottom: 5px;
}
.progress-skill {
  margin-top: 30px;
}

/**********************************************
            Pricing Css
**********************************************/
.pricing-wrap {
}
.pricing-wrap .title {
  text-align: center;
}
.pricing-wrap .title h1 {
  display: inline-block;
}
.pricing-wrap .title h1:before {
  left: 50%;
  margin-left: -35px;
}
.tableList {
  list-style: none;
}
.pricing-table .pricingWrp {
  background: #fff;
  box-shadow: 0 0 20px rgb(0 0 0 / 12%);
  text-align: center;
  padding: 50px 0 50px 0;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.pricing-table .pricingWrp:hover {
  -moz-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.pricing-table h3 {
  font-size: 28px;
  color: #000;
  margin-bottom: 40px;
}
.dollarPrice {
  font-size: 60px;
  color: #fff;
  font-family: "Montserrat", sans-serif;
  padding: 30px 0;
  margin-bottom: 20px;
  background: #e20c0c;
  font-weight: 600;
}
.pricing-table li:nth-child(1) .dollarPrice {
  background: #ffb607;
}
.pricing-table li:nth-child(2) .dollarPrice {
  background: #1cc3f5;
}
.pricing-table li:nth-child(3) .dollarPrice {
  background: #e20c0c;
}

.dollarPrice span {
  font-size: 20px;
  color: #fff;
}
.tableList li {
  font-size: 18px;
  color: #000;
  line-height: 46px;
  font-family: "Montserrat", sans-serif;
}
.viewbtn {
  margin-top: 30px;
}
.viewbtn a {
  background: none;
  border: 2px solid #000;
  color: #000;
  padding: 14px 35px;
}
.viewbtn a:hover {
  background: #ffb607;
  color: #000;
  border-color: #ffb607;
}
.pricing-table li:nth-child(1) .viewbtn a {
  border-color: #ffb607;
  color: #ffb607;
}
.pricing-table li:nth-child(2) .viewbtn a {
  border-color: #1cc3f5;
  color: #1cc3f5;
}
.pricing-table li:nth-child(3) .viewbtn a {
  border-color: #e20c0c;
  color: #e20c0c;
}

.pricing-table li:nth-child(1) .viewbtn a:hover {
  background: #ffb607;
  color: #fff;
}
.pricing-table li:nth-child(2) .viewbtn a:hover {
  background: #1cc3f5;
  color: #fff;
}
.pricing-table li:nth-child(3) .viewbtn a:hover {
  background: #e20c0c;
  color: #fff;
}

/**********************************************
            FAQs Css
**********************************************/
.faqs {
  margin-bottom: 30px;
}
.faqs .panel-default > .panel-heading {
  background: none;
  border: none;
  padding: 0;
}
.panel-heading h4 {
  margin-bottom: 0;
}
.faqs h4 a {
  padding: 20px 55px 20px 15px;
  display: block;
  background: #ffb607;
  color: #fff;
  font-size: 18px;
  position: relative;
}
.panel-group h3 {
  font-size: 30px;
  margin-bottom: 10px;
  margin-top: 15px;
}
.panel-group .panel {
  border-radius: 0;
  margin-bottom: 20px;
  border: 1px solid #eee;
}
.panel-group .panel-body {
  line-height: 24px;
  color: #555;
  padding: 15px;
  font-size: 14px;
}
.faqs h4 .collapsed:hover,
.faqs h4 a:hover,
.faqs h4 a:active {
  text-decoration: none;
  background: #ffb607;
  color: #fff;
}
.faqs h4 a:before {
  content: "\f068";
  background: #fff;
  font-family: "FontAwesome";
  width: 30px;
  padding: 6px 0;
  color: #ffb607;
  position: absolute;
  right: 15px;
  top: 11px;
  font-size: 16px;
  display: inline-block;
  text-align: center;
  transition: 0.3s ease-in;
  -moz-transition: 0.3s ease-in;
  -webkit-transition: 0.3s ease-in;
  -ms-transition: 0.3s ease-in;
  line-height: 18px;
  border-radius: 50%;
}
.faqs h4 .collapsed:before {
  content: "\f067";
  font-family: "FontAwesome";
  background: #ffb607;
  width: 30px;
  padding: 6px 0;
  color: #fff;
  position: absolute;
  right: 15px;
  top: 11px;
  font-size: 16px;
  display: inline-block;
  text-align: center;
  transition: 0.3s ease-in;
  -moz-transition: 0.3s ease-in;
  -webkit-transition: 0.3s ease-in;
  -ms-transition: 0.3s ease-in;
  text-decoration: none;
}
.faqs h4 a.collapsed {
  background: #fff;
  color: #666;
  text-decoration: none;
}

.faqs h4 .collapsed:hover:before {
  background: #fff;
  color: #ffb607;
}

/*********************************************
   Inner Testimonials page
**********************************************/
.innerContent-wrap .testimonials-wrap {
  padding: 0;
  background: none;
}
.innerContent-wrap .testimonials-wrap ul li {
  margin: 50px 0;
  padding: 0 15px !important;
}
.innerContent-wrap .testimonials-wrap .starWrp li {
  padding: 0 !important;
  margin: 0 !important;
  color: #ffbc00;
}
.innerContent-wrap .testimonials-wrap .quote_icon {
  bottom: -34px;
}
.innerContent-wrap .testimonials-wrap .testimonials_sec {
  background: #fbfbfb;
  border-color: #eaeaea;
}
.innerContent-wrap .testimonials-wrap .testimonials_sec h3 {
  color: #000;
}
.innerContent-wrap .testimonials_sec p {
  color: #757575;
}
.innerContent-wrap .testimonials_sec h3 span {
  color: #929292;
}

/*********************************************
   404 page
**********************************************/
.four-zero-page {
  text-align: center;
  padding: 0 0 50px 0;
}
.four-zero-page h2 {
  font-size: 300px;
  color: #ff002a;
  font-weight: 800;
}
.four-zero-page h3 {
  color: #09c6ff;
  font-weight: 600;
  font-size: 30px;
  letter-spacing: 10px;
}
.four-zero-page p {
  letter-spacing: 2px;
  padding: 0 250px;
}
.four-zero-page .readmore {
  margin-top: 30px;
}

/*********************************************
   Login page
**********************************************/
.login-wrap {
  max-width: 800px;
  margin: 0 auto;
  background: #fffaee;
  border: 1px solid #ffb607;
  padding: 50px;
}
.loginWrp {
  border-top: 1px solid #ffb607;
  margin-top: 40px;
  padding-top: 30px;
}
.loginWrp .form-group .form-control {
  height: 50px;
  border-radius: 4px;
  font-size: 14px;
  border-color: #f9eccd;
}
.loginWrp .form-group {
  margin-top: 15px;
  margin-bottom: 0;
}
.loginWrp .send_btn {
  width: 100%;
  letter-spacing: 0;
  font-weight: 600;
}
.forgot_password {
  text-align: center;
}
.forgot_password a {
  color: #000;
}

/*********************************************
   RegisterWrp page
**********************************************/
.registerWrp {
  border-top: none;
  padding-top: 0;
  margin-top: 0;
}
.passnote {
  margin-bottom: 0px;
  font-size: 14px;
  margin-top: 10px;
  font-family: "Open Sans", sans-serif;
  color: #ababab;
}
.form_set {
  margin-bottom: 30px;
}
.already_account {
  text-align: center;
}
textarea.form-control {
  height: 150px !important;
}
.map {
  margin-left: 30px;
}

/*********************************************
   Inner Blog page
**********************************************/
.flight_wrap {
  position: relative;
  overflow: hidden;
  padding: 60px 0;
  background: #f9f9f9;
}
.flight_wrap .col-lg-4:nth-child(4) {
  margin-top: 30px;
}
.flight_wrap .col-lg-4:nth-child(5) {
  margin-top: 30px;
}
.flight_wrap .col-lg-4:nth-child(6) {
  margin-top: 30px;
}
.flight_wrap .col-lg-6:nth-child(3) {
  margin-top: 30px;
}
.flight_wrap .col-lg-6:nth-child(4) {
  margin-top: 30px;
}
.innerContent .blog_sec {
  margin-bottom: 30px;
}
.innerContent .blog-service {
  margin-top: 0;
}
.blog-pagination {
  margin-top: 30px;
}
.blog-pagination a {
  display: inline-block;
  width: 50px;
  height: 50px;
  text-align: center;
  text-decoration: none;
  line-height: 50px;
  color: #ffb607;
  border: 1px solid #ffb607;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  margin: 3px;
}
.blog-pagination a:hover,
.blog-pagination a.active {
  background: #ffb607;
  color: #ffffff;
}
.blogWrp {
  background: none;
}
.blogWrp .blog_sec {
  background: #fbfbfb;
}
.blogWrp h3 {
  font-size: 20px;
}
.blog_inner .blog_box .blogImg {
  position: relative;
}
.blog_inner .blog_box .path_box {
  background: #f7f7f7;
}
.blog_inner .blog_box h3 a {
  font-size: 24px;
  color: #000;
}
.blog_inner .blog_box h3 a:hover {
  text-decoration: none;
  color: #ffb607;
}
.blog_inner .blog_box {
  margin: 15px 0;
}
.bloggridWrp .blog_box {
  margin-top: 0;
  margin-bottom: 30px;
}
.blog_listWrp .blog_box .path_box {
  background: none;
  padding: 30px 30px 30px 0;
}
.bloggridWrp .blog_box {
  border: 1px solid #eee;
}
.single-widgets {
  border: 1px solid #e5e8f3;
  padding: 30px 28px 35px;
  margin: 0 0 40px;
}
.single-widgets:hover {
  border-color: #ffb607;
}
.single-widgets.widget_search form {
  position: relative;
}
.single-widgets.widget_search form button {
  position: absolute;
  right: 5px;
  top: 5px;
  bottom: 0px;
  cursor: pointer;
  border-radius: 4px;
  background: #ffb607;
  color: #ffffff;
  border: 0;
  font-size: 14px;
  line-height: 50px;
  height: 50px;
  width: 52px;
}
.categories {
  list-style: none;
}
.category_wrp {
  padding: 0;
  list-style: none;
}
.category_wrp li {
  display: block;
  border-bottom: 1px solid #eaeaea;
  padding: 11px 0;
}
.category_wrp li a {
  text-decoration: none;
  color: #000;
  font-size: 14px;
}
.category_wrp li a:hover {
  color: #ffb607;
}
.category_wrp li:first-child {
  border-top: none;
}
.category_wrp li a span {
  float: right;
}
.single-widgets.widget_search form input[type="search"] {
  border-radius: 4px;
  border: 1px solid #ebedf5;
  background: #f9fafc;
  font-size: 12px;
  padding: 10px 70px 10px 16px;
  height: 60px;
  width: 100%;
}
.single-widgets {
  text-align: left;
}
.single-widgets .title:after {
  left: 0;
  margin-left: 0;
}
.tagswrp li {
  display: inline-block;
  margin-bottom: 10px;
}
.tagswrp li a {
  color: #242323;
  line-height: 32px;
  display: block;
  border: 1px solid #ebedf5;
  text-decoration: none;
  padding: 6px 14px;
  font-size: 14px;
}
.tagswrp li a:hover {
  background: #ffb607;
  color: #fff;
  border-color: #ffb607;
}
.blog_del .blog-detail {
  text-align: left;
}
.blog_del .blog-detail p {
  font-size: 14px;
}
.blog_list .date {
  left: 1px;
}
.blog_list .blog-image {
  overflow: inherit;
}
.blog_list .blog-detail {
  text-align: left;
  padding: 20px 20px 20px 0;
}
.blog_list .blog-detail p {
  font-size: 14px;
}
.blog_list .readmore a {
  padding: 12px 22px;
}
.sidebar .sidebar-item {
  margin-bottom: 30px;
}
.sidebar-title {
  text-transform: capitalize;
  position: relative;
  font-size: 20px;
  margin-bottom: 0;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
  color: #212121;
  position: relative;
}
.blog_sec {
  margin-bottom: 30px;
}
.sidebar form input {
  width: 100%;
  border: 1px solid #f0f0f0;
  color: #696969;
  padding: 14px;
  outline: none;
  font-style: italic;
  border-radius: 30px;
}
.sidebar-item form {
  position: relative;
}
.sidebar form button {
  position: absolute;
  right: 0px;
  top: 0px;
  font-size: 24px;
  color: #696969;
  background: transparent;
  outline: none;
  padding: 15px 30px 15px 26px;
  border: none;
  color: #fff;
  background: #ffb607;
  cursor: pointer;
  border-radius: 0 30px 30px 0;
  line-height: 1;
}
.sidebar form button:hover {
  background: #000;
}
.sidebar .sidebar-categories {
  margin: 0px;
}
.sidebar .sidebar-categories li {
  list-style: none;
  border-bottom: 1px solid #f0f0f0;
}
.sidebar .sidebar-categories li:last-child {
  border: none;
}
.sidebar .sidebar-categories li a {
  display: block;
  font-weight: 700;
  font-size: 16px;
  color: #707070;
  text-decoration: none;
  padding: 10px 0px;
}
.sidebar .sidebar-categories li:first-child a {
  padding-top: 0px;
}
.sidebar .sidebar-categories li:last-child a {
  padding-bottom: 0px;
}
.sidebar .sidebar-categories li a:hover {
  padding-left: 16px;
  color: #92278f;
}
.sidebar .sidebar-categories li span {
  float: right;
}
.sidebar .sidebar-categories li a:hover span {
  height: 30px;
  width: 30px;
  text-align: center;
  background-color: #92278f;
  color: #fff;
  border-radius: 100%;
  display: inline-block;
  line-height: 30px;
}
.sidebar .sidebar-posts {
  margin: 0px;
}
.sidebar .sidebar-posts li {
  list-style: none;
  overflow: hidden;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #f0f0f0;
}
.sidebar .sidebar-posts li:last-child {
  margin: 0px;
  padding: 0px;
  border: none;
}
.sidebar .sidebar-posts .image {
  float: left;
  margin-right: 20px;
  width: 90px;
  margin-top: 7px;
}
.sidebar .sidebar-posts .content {
  display: table;
}
.sidebar .sidebar-posts a {
  display: block;
  text-decoration: none;
  font-size: 14px;
  line-height: 24px;
  color: #707070;
}
.sidebar .sidebar-posts li:hover a {
  color: #92278f;
}
.sidebar .sidebar-posts span {
  font-style: italic;
  font-size: 13px;
  color: #fa6f57;
}
.sidebar .sidebar-gallery {
  margin: 0px;
  overflow: hidden;
  margin: -5px;
}
.sidebar .sidebar-gallery li {
  list-style: none;
  float: left;
  width: calc(100% / 4 - 1px);
  padding: 5px;
}
.sidebar .sidebar-gallery li img {
  width: 100%;
}
.sidebar .sidebar-twitts {
  margin: 0px;
}
.sidebar .sidebar-twitts > li {
  list-style: none;
  overflow: hidden;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #f0f0f0;
}
.sidebar .sidebar-twitts > li:last-child {
  margin: 0px;
  padding: 0px;
  border: none;
}
.sidebar .sidebar-twitts .icon {
  float: left;
  font-size: 46px;
  color: #65bbf2;
  margin-right: 15px;
  line-height: 46px;
}
.sidebar .sidebar-twitts .content {
  display: table;
}
.sidebar .sidebar-twitts .content ul {
  margin: 0px;
}
.sidebar .sidebar-twitts .content ul li {
  list-style: none;
  display: inline-block;
}
.sidebar .sidebar-twitts .content ul li a {
  text-decoration: none;
}
.sidebar .sidebar-twitts .content p {
  margin: 0px;
}
.sidebar .sidebar-twitts .content > a {
  text-decoration: none;
}
.sidebar .sidebar-tags {
  margin: 0px;
  overflow: hidden;
  margin: -5px;
}
.sidebar .sidebar-tags li {
  list-style: none;
  display: inline-block;
  float: left;
  margin: 5px;
}
.sidebar .sidebar-tags li a {
  text-decoration: none;
  font-style: italic;
  font-weight: 700;
  font-size: 15px;
  color: #707070;
  border: 1px solid #f0f0f0;
  border-radius: 1px;
  padding: 8px 24px;
  display: inline-block;
}
.sidebar .sidebar-tags li a:hover {
  background-color: #92278f;
  border-color: #92278f;
  color: #fff;
}
.sidebar .class-details {
  margin: 0px;
  border: 1px solid #f0f0f0;
}
.sidebar .class-details li {
  overflow: hidden;
  list-style: none;
  border-bottom: 1px solid #f0f0f0;
  padding: 9px 15px;
}
.sidebar .class-details li:last-child {
  border: none;
}
.sidebar .class-details .name {
  float: left;
  width: 50%;
  font-size: 15px;
  color: #ffc000;
}
.sidebar .class-details .name i {
  margin-right: 10px;
  display: inline-block;
}
.sidebar .class-details .name i:before {
  font-size: 18px;
}
.sidebar .class-details .info {
  float: left;
  width: 50%;
  font-style: italic;
  font-weight: 700;
  font-size: 16px;
  color: #363636;
}
.sidebar .class-details .info.rating i {
  color: #fcb614;
}
.sidebar .class-details li:last-child .info {
  color: #92278f;
}
.sidebar {
  margin-left: 20px;
}
.blog_list {
  list-style: none;
}
.blog_list .col-lg-5 {
  padding-right: 0;
}
.blog_list .col-lg-7 {
  padding-left: 0;
}
.blog_list p {
  margin-bottom: 0;
  margin-top: 16px;
}
/*********************************************
     Categories
**********************************************/
.categories {
}
.categories li {
  position: relative;
  padding: 10px 0 !important;
}

.categories li a {
  margin-top: 0;
  text-transform: capitalize;
  color: #8f8f8f;
  text-decoration: none;
  font-size: 14px;
  position: relative;
  display: block;
}
.categories li a:hover {
  color: #ffb607;
}
.categories li a:hover:before {
  left: 15px;
  color: #fff;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.left_post {
  list-style: none;
  margin-top: 1rem;
}
.left_post li {
  border-bottom: 1px solid #efefef;
}
.left_post li .item {
  margin-bottom: 10px;
  padding-bottom: 10px;
}
.left_post li .item .thumb {
  float: left;
  height: 80px;
  overflow: hidden;
  position: relative;
  width: 80px;
  margin-right: 15px;
}
.left_post li .item .info {
  overflow: hidden;
}
.left_post li .info time {
  font-size: 13px;
  color: #929292;
  display: block;
  margin-bottom: 4px;
}
.left_post li .item .info .title {
  margin-bottom: 0;
  line-height: 1.5;
  font-size: 15px;
  font-weight: 600;
}
.left_post li .item .info .title a {
  display: inline-block;
  color: #474c40;
}
/*********************************************
     Tags
**********************************************/
.tags:after {
  display: table;
  clear: both;
  content: "";
}
.tags li {
  display: inline-block;
  float: left;
}
.tags a {
  font-size: 13px;
  color: #969595;
  border: 1px solid #dadada;
  padding: 7px 10px;
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 5px;
  text-transform: uppercase;
}
.tags a:hover {
  border: 1px solid #ffb607;
  background: #ffb607;
  color: #fff;
  text-decoration: none;
}

/*****:Media Quries :*****/

@media screen and (max-width: 1440px) {
  .navbar-light .navbar-nav .nav-link {
    padding: 34px 16px;
  }
}

@media screen and (max-width: 1350px) {
  .navbar-light .navbar-nav .nav-link {
    padding: 34px 10px;
  }
}

@media screen and (max-width: 1200px) {
  .header-wrap .navbar-light {
    max-width: 100%;
    display: block;
    flex: auto;
  }
  .header-wrap .col-lg-6 {
    max-width: 75%;
    flex: 75%;
  }
  .navbar-light .navbar-nav .nav-link {
    padding: 34px 16px;
  }
  .header-wrap .logo {
    text-align: center;
    background: #f3f3f3;
    padding: 15px;
  }
  .class-wrap .owl-nav {
    display: none;
  }
  .blog_inner .blog_box h3 a {
    font-size: 20px;
  }
  #counter {
    margin-top: 40px;
  }
  .header_phone a {
    font-size: 22px;
  }
  .header_phone {
    padding-left: 35px;
    margin-right: 25px;
  }
  .law-wrap .title h1 {
    font-size: 32px;
  }
  .blog-detail h3 a {
    font-size: 20px;
  }
  .contact_form {
    padding: 20px;
  }
  .contact_info {
    padding: 20px;
  }
  .flightImg img {
    width: 100%;
  }
  .contact_info:after {
    bottom: -100px;
    right: -220px;
  }
  .flight-wrap .owl-nav {
    display: none;
  }
  .slider_info .sliderText {
    font-size: 50px;
  }

  .navbar-light .navbar-nav .nav-link {
    font-size: 14px;
  }
  .view_map {
    right: 50px;
  }
  .slider-wrap {
    padding: 220px 0 180px 0;
  }

  .about-wrap .title h1 {
    font-size: 30px;
    line-height: 40px;
  }
  .single-blog .blog-content a h3 {
    font-size: 18px;
  }
}

@media screen and (max-width: 990px) {
  .sidebar {
    margin-top: 30px;
  }
  .blog-pagination {
    margin-bottom: 30px;
  }
  .map {
    margin-top: 80px;
    margin-left: 0;
  }
  .address-item {
    margin-bottom: 30px;
  }
  .header_info {
    justify-content: center;
  }
  .blog_listWrp .blog_box {
    max-width: 370px;
    margin: 0 auto 30px auto;
  }
  .blog_listWrp .blog_box .path_box {
    padding: 30px;
  }
  .header-wrap .logo {
    background: none;
    text-align: left;
    padding: 0;
  }
  .categories_wrap {
    margin-top: 0;
  }
  .categories_wrap ul li {
    margin-top: 100px;
  }
  .coure-icon-inner {
    box-shadow: 0 0 15px rgb(0 0 0 / 15%);
  }
  .class_box {
    max-width: 300px;
    /* margin: 0 auto; */
    margin-left: 17px;
  }
  .about-wrap .title h1 span {
    display: inline-block;
  }
  .about_box {
    margin-top: 30px;
  }
  .learn_info {
    margin-top: 10px;
  }
  .edu_list li {
    margin: 30px 0;
  }
  .choice-wrap p {
    padding: 0;
  }
  .video-wrap .video {
    padding: 150px 0;
  }
  .gallery_box {
    display: block;
    text-align: center;
    margin-bottom: 40px;
  }
  .gallery_left {
    display: inline-block;
  }
  .teacherImg img {
    width: 100%;
  }
  .single-teachers {
    margin: 20px 0;
  }
  .blog-wrap .blog_box {
    max-width: 370px;
    margin: 20px auto;
  }
  .blog-wrap ul li:first-child .blog_box {
    margin-top: 0;
  }
  .slidertext3 {
    line-height: 20px !important;
  }
  .about-wrap {
    padding-top: 30px;
  }
  .teacher-wrap .title {
    margin-bottom: 20px;
  }
  .choice-wrap {
    padding: 50px 0;
  }
  .navbar-toggler {
    padding: 4px 10px;
  }
  .sticky {
    display: none;
  }
  .navigation-wrap {
    margin-top: 0;
  }
  .inner_heading {
    padding: 30px 0;
  }
  .page_content .service_box {
    margin: 0 auto;
    padding: 0;
  }
  .staticbanner_wrap {
    margin-top: 0;
    padding: 60px 0;
  }
  .banner_col {
    margin-top: 0;
    max-width: inherit;
  }
  .video {
    margin-top: 0;
  }
  .service-wrap {
    padding: 60px 0 20px 0;
  }
  .videoslider {
    top: auto;
  }
  .videoslider h1 {
    font-size: 50px;
  }
  .gallery-wrap .col-lg-4:nth-child(2) .galleryImg + .galleryImg {
    margin-top: 0;
  }
  .enrol-wrap p {
    padding: 0;
  }
  .appointment-wrap {
    text-align: center;
  }
  .blogImg img {
    width: 100%;
  }
  .blog_list .blogImg img {
    width: auto;
  }
  .blog_list li {
    max-width: 356px;
    margin: 0 auto;
  }
  .videoWrp,
  .hero-wrapper {
    height: 450px;
  }
  .staticbanner_wrap .contact_form {
    padding: 20px;
  }
  .staticbanner_wrap .contact_form {
    margin-top: 40px;
  }
  .service_box {
    padding: 28px;
    max-width: 350px;
    margin: 0 auto;
  }
  .service-wrap h3 {
    margin-bottom: 30px;
    font-size: 24px;
  }
  .innerHeading-wrap {
    margin-top: 0;
    padding: 40px 0;
  }
  .quote-btn a {
    display: inline-block;
    font-size: 14px;
    padding: 14px 24px;
  }
  .header-wrap .atlanta_logo {
    text-align: center;
  }
  .tp-banner {
    margin-top: 0;
  }
  .header_info {
    float: none;
  }
  .law-wrap:before {
    display: none;
  }
  .offset-1 {
    margin-left: 0;
  }
  .contact_info:after {
    display: none;
  }
  .copyright-wrap,
  .credits {
    text-align: center;
  }
  .contact-wrap:after {
    display: none;
  }
  .contact-wrap:after,
  .contact-wrap:before {
    width: 100%;
  }
  .contact-wrap:before {
    height: 50%;
  }
  .law-wrap .offset-6 {
    margin-left: 0;
  }
  .aboutImg {
    text-align: center;
    margin-top: 40px;
  }
  .header-wrap .navbar {
    float: none;
  }
  .advice-wrap p {
    padding: 0;
  }
  .counterbox {
    margin: 15px 0;
  }
  .navbar-toggler {
    background: #fff;
  }
  .navbar-light .navbar-nav .nav-link {
    color: #fff;
    padding: 10px 16px;
  }
  .navbar-light .navbar-nav .nav-link:hover {
    background: #000;
    color: #fff !important;
  }
  .navbar-light .navbar-nav .active > .nav-link {
    background: #000;
    color: #fff;
  }
  .footer-wrap .credits {
    margin-top: 10px;
    text-align: center;
  }
  .header_logo {
    text-align: center;
  }
  .header-wrap {
    position: static;
    padding: 15px 0;
  }
  .slider-wrap {
    padding: 120px 0 80px 0;
    height: 450px;
  }
  .slider_info .sliderText {
    line-height: 55px;
  }
  .sinceWrp {
    text-align: center;
  }
  .followus {
    display: none;
  }
  .navbar-light .navbar-nav .active > .nav-link,
  .navbar-light .navbar-nav .nav-link.active,
  .navbar-light .navbar-nav .nav-link.show,
  .navbar-light .navbar-nav .show > .nav-link {
    border: none;
  }
  .navbar-light .navbar-nav .nav-link {
    border: none;
  }
  .navbar-light .navbar-nav .active > .nav-link {
    background: #bd935c;
  }
  .navigationWrp {
    padding: 0;
  }
  .header_info {
    text-align: center;
    border-top: 1px solid #ddd;
    margin-top: 14px;
    padding-top: 15px;
  }
  .services-wrap .laptopImg {
    width: auto;
  }
  .navbar-collapse.show {
    visibility: visible;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  .newsletter-wrap {
    text-align: center;
  }
  .copyright-wrap {
    text-align: center;
  }
  .navbar-collapse {
    position: fixed;
    top: 0px;
    bottom: 0;
    left: 100%;
    width: 80%;
    padding-right: 1rem;
    padding-left: 1rem;
    overflow-y: auto;
    visibility: hidden;
    background-color: rgba(0, 0, 0, 0.8);
    transition: visibility 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out, visibility 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out, visibility 0.3s ease-in-out,
      -webkit-transform 0.3s ease-in-out;
    z-index: 10000;
  }
  .navbar-nav {
    clear: both;
  }
  .navigationWrp .collapse:not(.show) {
    display: inline-block;
  }
  .navbar-light .navbar-nav .nav-link {
    color: #fff;
  }
  .navbar-light .navbar-nav .nav-link {
    color: #fff;
    font-size: 14px;
    z-index: 1000;
    font-weight: 400;
  }
  .navbar-toggler {
    position: absolute;
    top: 20px;
    right: 20px;
    background: #fff;
    display: block;
  }
  .header_phone {
    margin-top: 0;
  }
  .close-toggler {
    background: none;
    color: #fff;
    padding: 5px;
    border: none;
    margin-top: 10px;
    font-size: 24px;
    float: right;
    display: block;
  }
  .navbar-nav > li {
    display: block;
  }
  .navbar {
    text-align: left;
  }
  .navbar-light .navbar-nav .nav-link:hover {
    background: #ffb607;
    color: #fff;
  }
  .navbar {
    margin: 0;
  }
  .header-wrap .conInfo span {
    text-align: left;
  }
  .about_box {
    margin-left: 0;
  }
  .navbar-nav li a + i {
    display: block;
  }
  .navbar-nav > li > ul {
    width: 100%;
    position: static;
    display: none;
    margin-left: 0;
    opacity: 1;
    visibility: visible;
  }
  .blog-section .col-lg-4:nth-child(4) .single-blog .blog-content {
    margin-bottom: 25px;
    padding-bottom: 30px;
  }
  .flight_wrap .col-lg-4:nth-child(3) .flight {
    margin-top: 25px;
  }
  .flight_wrap .col-lg-4:nth-child(2) .flight {
    margin-top: 25px;
  }
  .flight_wrap .col-lg-6:nth-child(2) {
    margin-top: 30px;
  }
  .school_box {
    margin-bottom: 40px;
  }
  .four-zero-page h2 {
    font-size: 250px;
  }
  .four-zero-page p {
    padding: 0;
  }
  .pricing-table > li {
    margin: 20px 0;
  }
  .galleryImg {
    margin-bottom: 20px;
  }
  .dropDownsection {
    position: absolute;
    z-index: 999;
    top: 5rem;
    right: 15rem;
    width: 200px;
    height: 220px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    background-color: white;
  }
  .unOrderedList li {
    margin: 4px;
    display: flex;
    flex-direction: column;
    margin-right: 15px;
  }
}

@media screen and (max-width: 767px) {
  .categories_wrap {
    z-index: 0;
  }
  .dropDownsection {
    position: absolute;
    z-index: 999;
    top: 5rem;
    right: 10rem;
    width: 200px;
    height: 230px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    background-color: white;
  }
  .unOrderedList li {
    margin: 6px;
    display: flex;
    flex-direction: column;
  }
  .flex-column.dropDownsection ul li {
    margin-bottom: -8px; /* Adjust this value to reduce the space */
  }
  .videoslider h1 {
    font-size: 34px;
  }
  .videoslider p {
    font-size: 24px;
  }
  .four-zero-page h2 {
    font-size: 150px;
  }
  .four-zero-page h3 {
    letter-spacing: 1px;
  }
  .top_right {
    float: none;
  }
  .logo {
    margin-right: 70px;
  }
  .school_sec {
    margin-top: 0;
  }
  .topbar-wrap .phone {
    margin-left: 0;
    margin-top: 10px;
  }
  .videoWrp,
  .hero-wrapper {
    height: 300px;
  }
  .footer_icon {
    text-align: center;
  }
  .blog_list .blog-detail {
    padding: 20px;
  }
  .navbar li {
    padding: 0;
  }
  .social-media,
  .topbar-wrap {
    text-align: center;
  }
  .header_info ul {
    margin-top: -20px;
    margin-left: -70px;
    text-align: left;
  }
  .header_info li {
    display: block;
    margin: 20px 0;
    padding: 0;
    border: none;
  }

  .loginwrp a {
    padding: 13px 24px;
  }
  .about-wrap {
    padding: 50px 0;
  }
  .slidertext3 {
    line-height: 10px !important;
  }
  .atlanta_logo {
    text-align: left !important;
    margin-right: 76px;
  }
  .advice-wrap .title h1 {
    font-size: 32px;
  }
  .advice-wrap .phonewrp a {
    font-size: 36px;
  }
  .advice-wrap .phonewrp:before {
    top: 0;
  }
  .header-wrap {
    text-align: center;
  }
  .contact-list {
    position: static;
    margin: 0 auto 50px auto;
  }
  .conInfo {
    margin-top: 30px;
  }
  .work-wrap .title p {
    font-size: 16px;
  }
  .work-wrap .title p:before,
  .work-wrap .title p:after {
    display: none;
  }
  .title h1 {
    font-size: 36px;
  }
  .media_logo {
    margin-top: 30px;
  }
  .medialinker_img img {
    width: 100%;
  }
  .work-wrap .readmore a {
    font-size: 18px;
    padding: 12px 30px;
  }
  .slider_info .sliderText {
    font-size: 22px;
    line-height: 30px;
  }
  .sinceWrp {
    font-size: 18px;
    color: #000;
  }
  .copyright-content p {
    text-align: center;
  }
  .credit {
    text-align: center;
  }
  .readmore a {
    font-size: 14px;
    padding: 14px 24px;
  }
  .newsletter-wrap .form-control {
    font-size: 14px;
    padding: 34px 22px 34px 65px;
  }
  .newsletter-wrap .form_icon {
    font-size: 28px;
    margin-top: -13px;
  }
  .newsletter-wrap {
    padding: 35px 15px;
  }
  .gallery_style1 .col-lg-4:nth-child(2) .galleryImg {
    margin-top: 30px;
  }
  .gallery_style1 .col-lg-4:nth-child(3) .galleryImg {
    margin-top: 30px;
  }
  .gallery_style1 .col-lg-4:nth-child(4) .galleryImg {
    margin-top: 30px;
  }
  .gallery_style1 .col-lg-4:nth-child(5) .galleryImg {
    margin-top: 30px;
  }
  .gallery_style1 .col-lg-4:nth-child(6) .galleryImg {
    margin-top: 30px;
  }
  .gallery_style1 .col-lg-3:nth-child(2) .galleryImg {
    margin-top: 30px;
  }
  .gallery_style1 .col-lg-3:nth-child(3) .galleryImg {
    margin-top: 30px;
  }
  .gallery_style1 .col-lg-3:nth-child(4) .galleryImg {
    margin-top: 30px;
  }
  .gallery_style1 .col-lg-3:nth-child(5) .galleryImg {
    margin-top: 30px;
  }
  .gallery_style1 .col-lg-3:nth-child(6) .galleryImg {
    margin-top: 30px;
  }
  .gallery_style1 .col-lg-3:nth-child(7) .galleryImg {
    margin-top: 30px;
  }
  .gallery_style1 .col-lg-3:nth-child(8) .galleryImg {
    margin-top: 30px;
  }
  .gallery_style1 .col-lg-6:nth-child(2) .galleryImg {
    margin-top: 30px;
  }
  .gallery_style1 .col-lg-6:nth-child(3) .galleryImg {
    margin-top: 30px;
  }
  .gallery_style1 .col-lg-6:nth-child(4) .galleryImg {
    margin-top: 30px;
  }
  .single-blog a img {
    width: 100%;
  }
  .blog-section .col-lg-4:nth-child(1) .single-blog .blog-content {
    margin-bottom: 15px;
    padding-bottom: 30px;
  }
  .blog-section .col-lg-4:nth-child(2) .single-blog .blog-content {
    margin-bottom: 15px;
    padding-bottom: 30px;
  }
  .blog-section .col-lg-4:nth-child(3) .single-blog .blog-content {
    margin-bottom: 15px;
    padding-bottom: 30px;
  }
  .blog-section .col-lg-4:nth-child(4) .single-blog .blog-content {
    margin-bottom: 15px;
    padding-bottom: 30px;
  }
  .blog-section .col-lg-4:nth-child(5) .single-blog .blog-content {
    margin-bottom: 15px;
    padding-bottom: 30px;
  }

  .navigation-wrap {
    .navbar-nav {
      .nav-item {
        .nav-link {
          display: flex;
          align-items: center;
          gap: 15px; /* Adjust the gap as needed */

          .fas {
            margin-left: -30px;
            margin-right: 5px; /* Add margin to the right of the icons */
          }
        }
      }
    }
  }
  .address-section h3 {
    margin-top: 15px; /* Add margin bottom to create space between the heading and address */
    color: white; /* Set text color */
  }
  
}

@media screen and (max-width: 480px) {
  .dropDownsection {
    position: absolute;
    z-index: 999;
    top: 5rem;
    right: 2rem;
    width: 180px;
    height: 200px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    background-color: white;
  }

  .unOrderedList li {
    margin: 4px;
    display: flex;
    flex-direction: column;
  }
  .videoslider h1 {
    font-size: 24px;
  }
  .four-zero-page h2 {
    font-size: 100px;
  }
  .four-zero-page h3 {
    letter-spacing: 1px;
    font-size: 20px;
  }
  .four-zero-page p {
    letter-spacing: 0px;
  }
  .enrol-wrap .title h1 {
    font-size: 26px;
  }
  .banner_wrap .title h1 {
    font-size: 32px;
  }
  .videoslider p {
    font-size: 16px;
  }
  .videoWrp,
  .hero-wrapper {
    height: 200px;
  }
  .client_box {
    display: block;
  }
  .advice-wrap .phonewrp:before {
    left: 50%;
    margin-left: -31px;
  }
  .advice-wrap .phonewrp {
    padding-left: 0;
    padding-top: 80px;
  }
  .page-navigation .page-go-link,
  .page-navigation .page-go {
    margin-left: auto;
    margin-right: auto;
  }
  .newsletter-wrap form input[type="submit"] {
    padding: 0 16px;
    font-size: 14px;
  }
  .newsletter-wrap .form_icon {
    font-size: 22px;
    margin-top: -10px;
  }
  .newsletter-wrap .form-control {
    padding: 32px 20px 32px 56px;
    font-size: 12px;
  }
  .maprwp iframe {
    height: 200px;
  }
  .contact-info-icon {
    margin: 30px auto;
  }
  .single-contact-info {
    display: block;
    text-align: center;
  }
  .contact-info-text {
    margin: 30px 0;
  }
  .about-wrap h3 {
    font-size: 20px;
  }
  .header-wrap {
    text-align: left;
  }
  .footer_links {
    border: none;
  }
  .footer_links li {
    display: block;
    text-align: left;
    border-bottom: 1px solid #3d5565;
    padding: 5px 0;
  }
  .footer_links li a {
    font-weight: normal;
  }
  .footer_links li:first-child {
    border-top: 1px solid #3d5565;
  }
  .footer_links {
    margin-top: 20px;
  }
  .testimonial-wrap .owl-nav {
    position: static;
    margin-top: 20px;
    text-align: center;
  }
  .header_logo {
    padding-right: 80px;
  }
  .about-wrap .title h1 {
    font-size: 24px;
    line-height: 26px;
  }
  .title p {
    letter-spacing: 1px;
  }
  .title p:before,
  .title p:after {
    display: none !important;
  }
  .contact-list li .icon img {
    width: 30px;
  }
  .contact-list p {
    font-size: 14px;
  }
  .law-wrap .title h1 {
    font-size: 20px;
  }
  .contact-wrap:before {
    height: 56%;
  }
  .newsletter-wrap .title h1 {
    font-size: 22px;
  }
  .newsletter-wrap .title h1 span {
    font-size: 18px;
  }
  .contact-wrap .title h1 {
    font-size: 22px;
  }
  .blog-detail p {
    font-size: 14px;
  }
  .lawflex .law_box {
    font-size: 20px;
  }
  .contact_info {
    padding: 20px !important;
  }
  .contact_box {
    margin-bottom: 8px;
  }
  .emailtext {
    font-size: 15px !important;
    font-weight: normal;
  }
  .title h1 {
    font-size: 26px;
  }
  .title p {
    font-size: 14px;
  }
  .blog_info {
    padding: 20px;
  }
  .blog_List span {
    padding-right: 12px;
  }
  .blog-wrap h3 {
    line-height: 28px;
    font-size: 20px;
  }
  .login-wrap {
    padding: 20px;
  }
  .form-group .btn {
    padding: 16px 30px;
    font-size: 14px;
  }
  .slider-wrap {
    padding: 80px 0;
  }
  .slider_info .sliderText {
    font-size: 16px;
    line-height: 20px;
  }
  .copyright-wrap .footer_links li {
    border-bottom: 1px solid #d0d0d0;
  }
  .copyright-wrap .footer_links li:first-child {
    border-top: none;
  }
  .copyright-wrap .footer_links li:last-child {
    border-bottom: none;
  }
  .gallery_style1 .col-lg-4:nth-child(2) .galleryImg {
    margin-top: 15px;
  }
  .gallery_style1 .col-lg-4:nth-child(3) .galleryImg {
    margin-top: 15px;
  }
  .gallery_style1 .col-lg-4:nth-child(4) .galleryImg {
    margin-top: 15px;
  }
  .gallery_style1 .col-lg-4:nth-child(5) .galleryImg {
    margin-top: 15px;
  }
  .gallery_style1 .col-lg-4:nth-child(6) .galleryImg {
    margin-top: 15px;
  }
  .gallery_style1 .col-lg-3:nth-child(2) .galleryImg {
    margin-top: 15px;
  }
  .gallery_style1 .col-lg-3:nth-child(3) .galleryImg {
    margin-top: 15px;
  }
  .gallery_style1 .col-lg-3:nth-child(4) .galleryImg {
    margin-top: 15px;
  }
  .gallery_style1 .col-lg-3:nth-child(5) .galleryImg {
    margin-top: 15px;
  }
  .gallery_style1 .col-lg-3:nth-child(6) .galleryImg {
    margin-top: 15px;
  }
  .gallery_style1 .col-lg-3:nth-child(7) .galleryImg {
    margin-top: 15px;
  }
  .gallery_style1 .col-lg-3:nth-child(8) .galleryImg {
    margin-top: 15px;
  }
  .gallery_style1 .col-lg-6:nth-child(2) .galleryImg {
    margin-top: 15px;
  }
  .gallery_style1 .col-lg-6:nth-child(3) .galleryImg {
    margin-top: 15px;
  }
  .gallery_style1 .col-lg-6:nth-child(4) .galleryImg {
    margin-top: 15px;
  }
}

/* 35 . Payment Css */

.qr-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 20px; /* Adjust as needed */
}
.content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 20px; /* Adjust as needed */
}

.submit {
  margin-top: 15px; /* Adjust as needed */
  border-radius: 4px;
  background: green;
}
.input {
  width: 300px !important;
}
.lable {
  width: 300px !important;
}
.button {
  margin-left: -180px;
  margin-bottom: 10px;
}

@media only screen and (max-width: 768px) {
  /* Adjust button styles for mobile devices */
  .button {
    margin-left: 0;
  }
}
/* classesdetails enroll and book now buttons */
.enroll-button {
  width: 250px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.booknow-button {
  width: 250px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.course-description {
  color: var(--body-color);
  display: -webkit-box;
  -webkit-line-clamp: 5; /* Number of lines to display */
  -webkit-box-orient: vertical;
  white-space: normal; /* Prevent text from wrapping */
  overflow: hidden; /* Hide overflowing content */
  text-overflow: ellipsis;
}

.booknowbutton {
  margin-right: 40px !important;
  margin-top: -45px !important;
}
.enrollcontainer {
  margin-top: 0px !important;
}

@media screen and (max-width: 480px) {
  .booknowbutton {
    margin-right: 0 !important;
  }
  .class_left h3 {
    /* Your styles here */
    width: 220px !important; /* Adjust the width as needed */
  }
  .enrollcontainer {
    margin-top: 20px !important;
    margin-left: -5px;
  }
}
