.carousel-inner img {
  width: 100%;
  height: 100%; /* Ensures the aspect ratio is maintained */
  object-fit: cover; /* Covers the entire area while maintaining aspect ratio */
}
.carousel-caption {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #fff;
}

.carousel-caption p {
  color: white;
  font-size: 18px;
  margin: 0;
}
.carousel-caption h3 {
  color: white;
  font-size: 40px;
  font-weight: bold;
  margin: 0;
}

.carousel-inner .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4); /* Black with 40% opacity */
}

.carousel-inner {
  position: relative;
  width: 100%;
  height: 100%;
}

.carousel-item {
  position: relative;
}

/* Media query for mobile screens */
@media (max-width: 768px) {
  .carousel-item {
    justify-content: center;
    align-items: center;
    height: 350px;
  }
  .carousel-caption {
    width: 100%;
    top: 55%;
  }
  .carousel-caption p {
    color: white;
    font-size: 3.8vw;
  }
  .carousel-caption h3 {
    color: white;
    font-size: 5.2vw;
    font-weight: normal;
  }
  .carousel-control-prev,
  .carousel-control-next {
    display: none; /* Hide on screens smaller than 768px (mobile view) */
  }
}